import { MachineTree } from 'types/machine';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { fetchAllMachinesParts } from 'services/API/machine';
import { useQueryWithError } from 'hooks/useQueryWithError';

export const useMachineTree = (
  select?: (data: MachineTree[]) => MachineTree[]
) => {
  return useQueryWithError<MachineTree[]>({
    queryKey: machinesQueryKeys.allMachines(),
    queryFn: fetchAllMachinesParts,
    retry: 0,
    select,
  });
};

// recursively load machine of tree by id
export const findMachineById = (
  data: MachineTree[],
  id: string
): MachineTree[] => {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of data) {
    if (item.id === id) {
      return [item];
    }

    if (item.children) {
      const result = findMachineById(item.children, id);

      if (result.length) {
        return [...result];
      }
    }
  }

  return [];
};

export const useFindMachineTree = (id: string | undefined) => {
  return useMachineTree((data) => (id ? findMachineById(data, id) : []));
};
