import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useAbortQueryOnCleanup = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  useEffect(
    () => () => {
      queryClient.cancelQueries({
        queryKey,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
