import React from 'react';

import { Column } from 'components/BasicTable';
import { SortingType } from 'hooks/Table/useTableSorting';

import { Machine } from 'types/machine';
import { DateRanges } from 'enums/DateRanges.enum';
import { DashboardTableChart } from 'views/ChartView/DashboardTableChart';

export const columns: Column<Machine>[] = [
  {
    id: 'name',
    label: 'tableHeader.name',
    minWidth: 200,
    format: ({ label }) => label,
    sortingType: SortingType.LABEL,
  },
  {
    id: 'graph',
    label: 'tableHeader.past24',
    minWidth: 300,
    cellPadding: '0',
    format: (item, settings) => (
      <DashboardTableChart
        itemId={item.id}
        dateRange={DateRanges.DAY}
        isShowTicks={settings?.isLastItem}
      />
    ),
  },
];
