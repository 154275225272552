import { CustomerEvent } from 'types/event';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import { CurveData, SensorValueType } from 'types/sensor';
import { useMemo } from 'react';

interface Data {
  events: CustomerEvent[];
  startDate: string | null;
}
export const useSensorNoDataEvent = (data: CurveData[]) => {
  const { t } = useTranslation();

  const defaultNoDateEvent = {
    user_id: '',
    description: '',
    label: t('emptyMessage.default'),
    part: { id: '', identifier: '', label: '' },
    type: {
      id: '',
      icon_path: '',
      label: '',
      hexcolor: theme.iconColor,
    },
  };

  const { events } = useMemo(() => {
    // Make an array of all data points from different sensor property
    const dataArray = data.reduce<SensorValueType[]>(
      (acc, item) => [...acc, ...item.values],
      []
    );

    // We need this Set to filter data when we have "value" (not "NaN") on one sensor property but don't have it in another
    const datesWithValue = new Set(
      dataArray.reduce<string[]>(
        (acc, { date, value }) => (value !== 'NaN' ? [...acc, date] : acc),
        []
      )
    );

    const values = dataArray
      .filter(
        ({ value, date }) => !(value === 'NaN' && datesWithValue.has(date))
      )
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return values.reduce<Data>(
      (acc, item, index, array) => {
        if (item.value?.toString() === 'NaN' && !acc.startDate) {
          return { ...acc, startDate: item.date };
        }

        const isLastIndex = array.length - 1 === index;
        if (isLastIndex && acc.startDate) {
          return {
            ...acc,
            events: [
              ...acc.events,
              {
                ...defaultNoDateEvent,
                id: `${index}`,
                start: acc.startDate,
                end: item.date,
              },
            ],
            startDate: null,
          };
        }

        if (
          item.value?.toString() !== 'NaN' &&
          acc.startDate &&
          acc.startDate !== item.date
        ) {
          return {
            ...acc,
            events: [
              ...acc.events,
              {
                ...defaultNoDateEvent,
                id: `${index}`,
                start: acc.startDate,
                end: item.date,
              },
            ],
            startDate: null,
          };
        }

        return acc;
      },
      { events: [], startDate: null }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return events;
};
