import { api } from 'services/API/client';
import { Tenant } from 'types/tenant';

export const getTenants = async (): Promise<Tenant[]> => {
  const {
    data: { data },
  } = await api.get('/tenants');

  return data;
};
