import React from 'react';
import { Column } from 'components/BasicTable/BasicTable';
import { User } from 'types/user';
import { UserMenu } from 'views/Administration/UserMenu';
import { UserRolesCell } from 'views/Administration/UserRolesCell';
import { EMPTY_STATE } from 'utils/constants';

export const columns: Column<User>[] = [
  {
    id: 'username',
    label: 'tableHeader.username',
    minWidth: 200,
  },
  {
    id: 'email',
    label: 'tableHeader.email',
    minWidth: 200,
  },
  {
    id: 'tenant',
    label: 'tableHeader.company',
    minWidth: 200,
    format: ({ tenant }) => tenant.label || EMPTY_STATE,
  },
  {
    id: 'roles',
    label: 'tableHeader.roles',
    minWidth: 200,
    format: ({ roles }) => <UserRolesCell roles={roles} />,
  },
  {
    id: 'menu',
    label: '',
    minWidth: 10,
    align: 'right',
    format: (user) => <UserMenu user={user} />,
  },
];
