import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';

import {
  TextField,
  Select,
  Flex,
  Button,
  TextArea,
  Text,
} from '@radix-ui/themes';

import { useAuth } from 'hooks/Auth/useAuth';

import {
  CreateEventFormData,
  CustomerEvent,
  EditEventFormData,
} from 'types/event';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { eventsQueryKeys } from 'enums/EventsQueryKeys.enum';

import { createEvent, editEvent } from 'services/API/event';
import { z } from 'services/SchemaValidation';

import { getUTCDateForPost, parseDatetime } from 'utils/helpers';

import { MachineTree } from 'types/machine';
import { CustomerTypeData } from 'types/state';

import { queryClient } from 'index';
import { parseISO } from 'date-fns';

import { ErrorMessage } from 'components/ErrorMessage';
import { fetchCustomerTypes } from 'services/API/customerTypes';
import { customerTypesQueryKeys } from 'enums/CustomerTypesQueryKeys.enum';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { useMutationWithError } from 'hooks/useMutationWithError';

interface EventFormProps {
  closeDialog?: () => void;
  event?: CustomerEvent;
}

export const EventForm: FC<EventFormProps> = ({ closeDialog, event }) => {
  const { t } = useTranslation();
  const EventFormSchema = z
    .object({
      label: z.string().min(4).max(30),
      description: z.string().min(4).max(200),
      type_id: z.string(),
      part_id: z.string(),
      start: z.coerce
        .date({
          errorMap: () => ({
            message: t('zod:errors.invalid_type_received_undefined'),
          }),
        })
        .transform(getUTCDateForPost),
      end: z.coerce
        .date({
          errorMap: () => ({
            message: t('zod:errors.invalid_type_received_undefined'),
          }),
        })
        .transform(getUTCDateForPost),
    })
    .refine((data) => data.end > data.start, {
      message: t('validation.notValidEndDate'),
      path: ['end'],
    });

  const { data: allParts } = useMachineTree();

  const { data: customerTypes } = useQueryWithError<CustomerTypeData[]>({
    queryKey: customerTypesQueryKeys.customerTypes,
    queryFn: fetchCustomerTypes,
    retry: 0,
  });

  const { mutate: sendCreateEvent } = useMutationWithError<
    CustomerEvent,
    CreateEventFormData
  >({
    mutationFn: createEvent,
    onSuccess(event) {
      toast.success(t('toast.eventCreated', { eventLabel: event.label }));
      queryClient.invalidateQueries({ queryKey: eventsQueryKeys.events });
      queryClient.invalidateQueries({
        queryKey: machinesQueryKeys.machineEvents,
      });
      if (typeof closeDialog === 'function') closeDialog();
    },
  });

  const { mutate: sendEditEvent } = useMutationWithError<
    CustomerEvent,
    EditEventFormData
  >({
    mutationFn: editEvent,
    onSuccess(event) {
      toast.success(t('toast.eventUpdated', { eventLabel: event.label }));
      queryClient.invalidateQueries({ queryKey: eventsQueryKeys.events });
      queryClient.invalidateQueries({
        queryKey: machinesQueryKeys.machineEvents,
      });
      if (typeof closeDialog === 'function') closeDialog();
    },
  });

  const { userData } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<CreateEventFormData>({
    mode: 'onSubmit',
    resolver: zodResolver(EventFormSchema),
    defaultValues: {
      label: event ? event.label : '',
      description: event ? event.description : '',
      type_id: event ? event.type.id : undefined,
      part_id: event ? event.part.id : undefined,
    },
  });

  if (!userData) {
    return null;
  }
  const minDate = watch('start');

  const renderSelectComponents = ({ id, label, children }: MachineTree) => (
    <>
      <Select.Item value={id}>{label}</Select.Item>
      <Select.Group>
        {children.map((child) => {
          const component = (
            <Select.Item value={child.id} key={child.id}>
              {child.label}
            </Select.Item>
          );
          renderSelectComponents(child);
          return component;
        })}
        <Select.Separator />
      </Select.Group>
    </>
  );

  const renderSelectMachines = ({ children, id, label }: MachineTree) => (
    <Select.Group key={id}>
      <Select.Label>{label}</Select.Label>
      {children.map(renderSelectComponents)}
    </Select.Group>
  );

  return (
    <>
      <Flex direction="column" gap="1">
        <Flex justify="between">
          <label htmlFor="eventLabel">
            <Text as="div" size="2" weight="bold">
              {t('forms.eventLabel.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.label} />
        </Flex>
        <TextField.Root
          autoFocus
          id="eventLabel"
          placeholder={t('forms.eventLabel.placeholder')}
          size="3"
          mb="3"
          {...register('label')}
        />

        <Flex justify="between">
          <label htmlFor="eventType">
            <Text as="div" size="2" weight="bold">
              {t('forms.eventType.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.type_id} />
        </Flex>
        <Controller
          control={control}
          name="type_id"
          render={({ field }) => (
            <Select.Root
              size="3"
              onValueChange={field.onChange}
              {...field}
              value={field.value as string}
            >
              <Select.Trigger
                mb="3"
                placeholder={t('forms.eventType.placeholder')}
              />
              <Select.Content position="popper" id="eventType">
                {customerTypes?.map((type) => (
                  <Select.Item value={type.id} key={type.id}>
                    {type.label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          )}
        />

        <Flex justify="between">
          <label htmlFor="eventDescription">
            <Text as="div" size="2" weight="bold">
              {t('forms.eventDescription.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.description} />
        </Flex>
        <TextArea
          id="eventDescription"
          placeholder={t('forms.eventDescription.placeholder')}
          size="3"
          mb="3"
          {...register('description')}
        />

        <Flex justify="between">
          <label htmlFor="eventPart">
            <Text as="div" size="2" weight="bold">
              {t('forms.eventPart.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.part_id} />
        </Flex>
        <Controller
          control={control}
          name="part_id"
          render={({ field }) => (
            <Select.Root
              size="3"
              onValueChange={field.onChange}
              {...field}
              value={field.value as string}
            >
              <Select.Trigger
                id="eventPart"
                mb="3"
                placeholder={t('forms.eventPart.placeholder')}
              />
              <Select.Content position="popper" id="eventType">
                {allParts?.map(renderSelectMachines)}
              </Select.Content>
            </Select.Root>
          )}
        />

        <Flex justify="between">
          <label htmlFor="eventStartDate">
            <Text as="div" size="2" weight="bold">
              {t('forms.eventStartDate.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.start} />
        </Flex>
        <TextField.Root
          type="datetime-local"
          id="eventStartDate"
          placeholder={t('forms.eventStartDate.placeholder')}
          size="3"
          mb="2"
          defaultValue={parseDatetime(
            event ? parseISO(event?.start) : undefined
          )}
          {...register('start')}
        />

        <Flex justify="between">
          <label htmlFor="eventEndDate">
            <Text as="div" size="2" weight="bold">
              {t('forms.eventEndDate.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.end} />
        </Flex>
        <TextField.Root
          min={parseDatetime(minDate)}
          type="datetime-local"
          id="eventEndDate"
          placeholder={t('forms.eventEndDate.placeholder')}
          size="3"
          mb="2"
          defaultValue={parseDatetime(event ? parseISO(event?.end) : undefined)}
          {...register('end')}
        />
      </Flex>

      <Flex gap="3" mt="4" justify="end">
        <Button onClick={closeDialog} variant="soft" color="gray">
          {t('forms.cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit((formData) => {
            if (event) {
              return sendEditEvent({
                ...formData,
                id: event.id,
                user_id: userData.id,
              });
            }
            return sendCreateEvent({ ...formData, user_id: userData.id });
          })}
        >
          {t('forms.save')}
        </Button>
      </Flex>
    </>
  );
};
