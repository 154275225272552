import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PermissionsGate } from 'components/PermissionsGate';

import { AppRoutes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { useAuth } from 'hooks/Auth/useAuth';
import { ExitIcon } from '@radix-ui/react-icons';
import { Text, Avatar, DropdownMenu } from '@radix-ui/themes';

import { Languages, languagesLabelsOptions } from 'enums/Languages.enum';
import { LocalStorage } from 'services/LocalStorage';
import { STORE_LANGUAGE } from 'utils/constants';

export const UserBar: FC = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage: Languages | null =
    LocalStorage.getItem(STORE_LANGUAGE);

  const { userData, logOut } = useAuth();
  const navigate = useNavigate();

  const [activeLanguage, setActiveLanguage] = useState<Languages>(
    selectedLanguage || Languages.EN
  );

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value as Languages);
    setActiveLanguage(value as Languages);
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Text>
          <Avatar
            size="2"
            radius="full"
            fallback={
              userData?.username
                .split(' ')
                .map((n) => n[0])
                .join('') || ''
            }
          />
        </Text>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Label>{userData?.username}</DropdownMenu.Label>
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>{t('language')}</DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent>
            <DropdownMenu.RadioGroup
              value={activeLanguage}
              onValueChange={handleLanguageChange}
            >
              {languagesLabelsOptions.map(({ value, label }) => (
                <DropdownMenu.RadioItem key={value} value={value}>
                  {label}
                </DropdownMenu.RadioItem>
              ))}
            </DropdownMenu.RadioGroup>
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
        <PermissionsGate scopes={[UserScopes.Administration]}>
          <DropdownMenu.Item
            onSelect={() => navigate(AppRoutes.Administration)}
          >
            {t('administration.adminPanel')}
          </DropdownMenu.Item>
        </PermissionsGate>
        <DropdownMenu.Separator />
        <DropdownMenu.Item color="red" onSelect={logOut}>
          <ExitIcon />
          {t('login.logOut')}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
