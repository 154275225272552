import { useEffect, useRef, useState } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
  const intervalRef = useRef<number | undefined>();
  const callbackRef = useRef<() => void>(callback);
  const [windowVisible, setWindowVisible] = useState(true);

  document.addEventListener(
    'visibilitychange',
    () => setWindowVisible(document.visibilityState === 'visible'),
    false
  );

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      if (windowVisible) callbackRef.current();
    }, delay);

    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, [delay, windowVisible]);

  return intervalRef;
};
