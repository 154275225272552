import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import {
  CaretRightIcon,
  MagnifyingGlassIcon,
  CardStackIcon,
  CheckboxIcon,
  ArrowRightIcon,
  BarChartIcon,
} from '@radix-ui/react-icons';
import './Navigation.css';
import {
  Badge,
  Box,
  Dialog,
  Link as ALink,
  Flex,
  Grid,
  Inset,
  Separator,
  Strong,
  Text,
  TextField,
} from '@radix-ui/themes';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'enums/Routes.enum';
import { useDemoEnvironment } from 'hooks/useDemoEnvironment';
import { ReactComponent as DashboardOutlined } from 'assets/icons/dashboard-outlined.svg';
import { ReactComponent as ComponentOutlined } from 'assets/icons/detail-outlined.svg';
import { ReactComponent as MachineOutlined } from 'assets/icons/machine-outlined.svg';
import { ReactComponent as SensorOutlined } from 'assets/icons/sensor-outlined.svg';
import { useTranslation } from 'react-i18next';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';

export const Navigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDemoEnvironment = useDemoEnvironment();
  const { data: allItems } = useMachineTree();

  const hasMachineComponents = allItems?.some(
    (item) => item.children.length > 0 && !item.is_sensor
  );
  return (
    <NavigationMenu.Root className="NavigationMenuRoot" orientation="vertical">
      <NavigationMenu.List className="NavigationMenuList">
        {isDemoEnvironment && (
          <>
            <NavigationMenu.Item>
              <Dialog.Root>
                <Dialog.Trigger>
                  <div className="NavigationMenuLink" role="button">
                    <MagnifyingGlassIcon className="icon" />
                    <Text as="div">{t('search')}</Text>
                  </div>
                </Dialog.Trigger>
                <Dialog.Content minHeight="100px">
                  <Inset side="all">
                    <TextField.Root
                      color="gray"
                      radius="full"
                      placeholder={t('searchPlaceholder')}
                      size="3"
                    >
                      <TextField.Slot>
                        <MagnifyingGlassIcon height="16" width="16" />
                      </TextField.Slot>
                    </TextField.Root>
                  </Inset>
                </Dialog.Content>
              </Dialog.Root>
            </NavigationMenu.Item>

            <NavigationMenu.Item>
              <NavigationMenu.Trigger asChild>
                <div className="NavigationMenuLink" role="button">
                  <CardStackIcon className="icon" />
                  <Text as="div">{t('inbox')}</Text>
                  <Badge variant="solid" radius="full" color="indigo">
                    2
                  </Badge>
                </div>
              </NavigationMenu.Trigger>
              <NavigationMenu.Content className="NavigationMenuContent">
                <Flex direction="column" width="600px" gapY="1" p="2">
                  <Flex direction="column">
                    <Flex justify="between" mb="1">
                      <Text size="1" weight="bold">
                        Health Score kritisch
                      </Text>
                      <Text size="1">vor 5 Min</Text>
                    </Flex>
                    <Text size="1" mb="1">
                      <Flex align="center">
                        <ALink color="gray" href="#">
                          Hauptmotor
                        </ALink>
                        <ArrowRightIcon />
                        <ALink color="gray" href="#">
                          Kühlsystem
                        </ALink>
                        <ArrowRightIcon />
                        <ALink color="gray" href="#">
                          Motortemperatursensor
                        </ALink>
                        <ArrowRightIcon />
                        <ALink color="gray" href="#">
                          TempTrend95
                        </ALink>
                      </Flex>
                    </Text>
                    <Text size="1" as="div">
                      <Strong>Vorschubgeschwindigkeitsabweichung</Strong> hat
                      den Grenzwert{' '}
                      <Text weight="bold" color="red">
                        &lt;65%
                      </Text>{' '}
                      Eingreifen könnte erforderlich sein.
                    </Text>
                    <Text as="p" size="1" mt="1">
                      Zusätzliche Informationen: Motortemperatur{' '}
                      <Badge color="gray">85°C</Badge>
                    </Text>
                  </Flex>
                  <Separator size="4" my="1" />
                  <Flex direction="column">
                    <Flex justify="between" mb="1">
                      <Text size="1" weight="bold">
                        Health Score kritisch
                      </Text>
                      <Text size="1">vor 30 Min</Text>
                    </Flex>
                    <Text size="1" mb="1">
                      <Flex align="center">
                        <ALink color="gray" href="#">
                          Vorschubeinheit
                        </ALink>
                        <ArrowRightIcon />
                        <ALink color="gray" href="#">
                          Steuersystem
                        </ALink>
                        <ArrowRightIcon />
                        <ALink color="gray" href="#">
                          Vorschubgeschwindigkeitssensor
                        </ALink>
                        <ArrowRightIcon />
                        <ALink color="gray" href="#">
                          FeedRateDeviationTrend10
                        </ALink>
                      </Flex>
                    </Text>
                    <Text size="1" as="div">
                      <Strong>Motortemperatur</Strong> hat den Grenzwert{' '}
                      <Text weight="bold" color="red">
                        &lt;70%
                      </Text>{' '}
                      Eingreifen könnte erforderlich sein.
                    </Text>
                    <Text as="p" size="1" mt="1">
                      Zusätzliche Informationen: Vorschubgeschwindigkeit{' '}
                      <Badge color="gray">38 mm/s</Badge>
                    </Text>
                  </Flex>
                </Flex>
              </NavigationMenu.Content>
            </NavigationMenu.Item>
            <NavigationMenu.Item>
              <div className="NavigationMenuLink" role="button">
                <CheckboxIcon className="icon" />
                <Text as="div">{t('tasks')}</Text>
              </div>
            </NavigationMenu.Item>
            <Box mb="3" />
          </>
        )}
        <NavigationMenu.Item>
          <NavigationMenu.Link
            className="NavigationMenuLink"
            onClick={() => navigate(generatePath(AppRoutes.Dashboard))}
          >
            <DashboardOutlined className="icon" />
            {t('dashboard')}
          </NavigationMenu.Link>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger asChild>
            <NavigationMenu.Link className="NavigationMenuLink">
              <MachineOutlined className="icon" />
              <Text as="div">
                {t('machines', { count: Number.MAX_SAFE_INTEGER })}
              </Text>
              <CaretRightIcon
                className="CaretRight"
                aria-hidden
                height="24px"
                width="24px"
              />
            </NavigationMenu.Link>
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="NavigationMenuContent">
            <ul className="NavigationMenuList">
              {allItems?.map((item) => (
                <li key={item.id}>
                  <NavigationMenu.Link asChild>
                    <Link
                      className="NavigationMenuLink"
                      to={generatePath(AppRoutes.Detail, {
                        id: item.id,
                      })}
                    >
                      <MachineOutlined className="icon" />
                      {item.label}
                    </Link>
                  </NavigationMenu.Link>
                </li>
              ))}
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        {hasMachineComponents && (
          <NavigationMenu.Item>
            <NavigationMenu.Trigger asChild>
              <NavigationMenu.Link className="NavigationMenuLink">
                <ComponentOutlined className="icon" />
                <Text as="div">
                  {t('components', { count: Number.MAX_SAFE_INTEGER })}
                </Text>
                <CaretRightIcon
                  className="CaretRight"
                  aria-hidden
                  height="24px"
                  width="24px"
                />
              </NavigationMenu.Link>
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="NavigationMenuContent">
              <Grid
                rows="repeat(3, min-content)"
                flow="column"
                width="auto"
                gapY="2"
                gapX="5"
                columns="1fr"
              >
                {allItems?.map(({ id, label, children }) => {
                  return (
                    <Box key={id} pt="2" pb="1" height="fit-content">
                      <Box>
                        <Text
                          as="div"
                          size="1"
                          weight="medium"
                          className="NavigationMenuGroup"
                        >
                          {label.toUpperCase()}
                        </Text>
                      </Box>
                      {
                        // eslint-disable-next-line camelcase
                        children.map(({ is_sensor, ...child }) =>
                          // eslint-disable-next-line camelcase
                          !is_sensor ? (
                            <Box key={child.id}>
                              <NavigationMenu.Link asChild>
                                <Link
                                  className="NavigationMenuLink"
                                  to={generatePath(AppRoutes.Detail, {
                                    id: child.id,
                                  })}
                                >
                                  <ComponentOutlined className="icon" />
                                  {child.label}
                                </Link>
                              </NavigationMenu.Link>
                            </Box>
                          ) : null
                        )
                      }
                    </Box>
                  );
                })}
              </Grid>
            </NavigationMenu.Content>
          </NavigationMenu.Item>
        )}

        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Link className="NavigationMenuLink" to={AppRoutes.ModelsList}>
              <SensorOutlined className="icon" />
              {t('models', { count: Number.MAX_SAFE_INTEGER })}
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Link className="NavigationMenuLink" to={AppRoutes.SensorsList}>
              <BarChartIcon className="icon" />
              {t('sensors', { count: Number.MAX_SAFE_INTEGER })}
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Item>
        <NavigationMenu.Indicator className="NavigationMenuIndicator">
          <div className="Arrow" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};
