import React, { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  condition: boolean;
}

export const If: FC<Props> = ({ condition, children }) => {
  if (condition) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};
