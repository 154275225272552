import React, { FC } from 'react';

import { Text, Flex, Badge, Skeleton } from '@radix-ui/themes';

import { ReactComponent as StateIconAlarm } from 'assets/icons/state-icon-alarm.svg';
import { ReactComponent as StateIconCritical } from 'assets/icons/state-icon-critical.svg';
import { ReactComponent as StateIconHealthy } from 'assets/icons/state-icon-healthy.svg';
import { ReactComponent as StateIconWarning } from 'assets/icons/state-icon-warning.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  health?: number | 'NaN';
}

const Health: FC<{ health: Props['health'] }> = ({ health }) => {
  const { t } = useTranslation();

  if (health === undefined) {
    return (
      <Skeleton>
        <Badge size="3">
          <Flex>{t('states.healthy')}</Flex>
        </Badge>
      </Skeleton>
    );
  }
  if (health === 'NaN' || health > 60) {
    return (
      <Badge color="green" size="3">
        <Flex align="center" width="20px" height="auto">
          <StateIconHealthy />
        </Flex>
        <Text>{t('states.healthy')}</Text>
      </Badge>
    );
  }
  if (health > 40) {
    return (
      <Badge color="yellow" size="3">
        <Flex align="center" width="20px" height="auto">
          <StateIconWarning />
        </Flex>
        <Text>{t('states.warning')}</Text>
      </Badge>
    );
  }
  if (health > 30) {
    return (
      <Badge color="yellow" size="3">
        <Flex width="20px" height="auto">
          <StateIconAlarm />
        </Flex>
        <Text>{t('states.alert')}</Text>
      </Badge>
    );
  }
  return (
    <Badge color="red" size="3">
      <Flex width="20px" height="auto">
        <StateIconCritical />
      </Flex>
      <Text>{t('states.critical')}</Text>
    </Badge>
  );
};

export const HealthBadge: FC<Props> = ({ health }) => {
  return (
    <Flex align="center" justify="start">
      <Health health={health} />
    </Flex>
  );
};
