import React from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuth } from 'hooks/Auth/useAuth';
import { useHasPermission } from 'services/Permissions/usePermissions';

export const RootComponent = () => {
  const { userData } = useAuth();
  const isSuperAdmin = useHasPermission([UserScopes.Administration]);

  if (!userData) {
    return <Navigate to={AppRoutes.Login} replace />;
  }

  if (isSuperAdmin) {
    return <Navigate to={AppRoutes.Administration} replace />;
  }

  return <Navigate to={AppRoutes.Dashboard} replace />;
};
