import { parseISO } from 'date-fns';

export const getUTCDateForView = (date: string | Date): Date => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;

  return new Date(
    parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60 * 1000
  );
};

export const getUTCDateForPost = (date: string | Date): Date => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;

  return new Date(
    parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60 * 1000
  );
};

export const getTodayStartByUTC = (): Date => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  return today;
};

export const getCurrentHourByUTC = (): Date => {
  const now = new Date();
  now.setUTCMinutes(0, 0, 0);

  return now;
};

export const parseDatetime = (date: Date | undefined) => {
  return date ? new Date(date).toISOString().slice(0, -8) : undefined;
};
