import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { BasicTable } from 'components/BasicTable';
import { EmptyMessage } from 'components/EmptyMessage/EmptyMessage';
import { If } from 'components/If';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { detailsQueryKeys } from 'enums/DetailsQueryKeys.enum';
import { AppRoutes } from 'enums/Routes.enum';

import { fetchDetails } from 'services/API/detail';
import { MachinesFullData, fetchMachines } from 'services/API/machine';

import { SortingOrder, SortingType } from 'hooks/Table/useTableSorting';

import { REFRESH_MACHINES_TABLE_INTERVAL } from 'utils/constants';

import { useQueryWithError } from 'hooks/useQueryWithError';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { MachineTree } from 'types/machine';
import { columns } from './columns';

const getChildrenById = (data: MachineTree[], id: string) => {
  let children: MachineTree[] = [];
  data.forEach((item) => {
    if (item.id === id) {
      children = item.children;
    } else if (item.children && !children.length) {
      children = getChildrenById(item.children, id);
    }
  });
  return children;
};

export const MostCriticalTable: FC<{ isMachinesList?: boolean }> = ({
  isMachinesList = false,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const options = {
    id: id || '',
    limit: 4,
    offset: 0,
    sort: SortingType.HEALTH,
    order: SortingOrder.ASC,
  };

  const { data: machineTree = [] } = useMachineTree();

  // fetch parts list
  const { data: partsList, isPending: partsListIsPending } =
    useQueryWithError<MachinesFullData>({
      queryKey: detailsQueryKeys.filteredDetails(options),
      queryFn: () => fetchDetails(options),
      retry: 0,
      refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
      enabled: !!id,
    });

  // fetch machines list
  const { data: machinesList, isPending: machinesListIsPending } =
    useQueryWithError<MachinesFullData>({
      queryKey: machinesQueryKeys.filteredMachines(options),
      queryFn: () => fetchMachines(options),
      retry: 0,
      refetchInterval: REFRESH_MACHINES_TABLE_INTERVAL,
      enabled: isMachinesList,
    });

  let preloadedList = id
    ? getChildrenById(machineTree || [], id || '')
    : machineTree;
  preloadedList = preloadedList.slice(0, options.limit);

  const itemList = partsList?.data || machinesList?.data || preloadedList;

  return (
    <Box width="100%">
      <BasicTable
        columns={columns}
        data={itemList || []}
        onClickByRow={(item) =>
          navigate(
            generatePath(item.is_sensor ? AppRoutes.Model : AppRoutes.Detail, {
              id: item.id,
            })
          )
        }
        loading={machinesListIsPending}
      />
      <If
        condition={
          (!partsListIsPending && !partsList?.data.length && !isMachinesList) ||
          (!machinesListIsPending &&
            !machinesList?.data.length &&
            isMachinesList)
        }
      >
        <EmptyMessage message={t('emptyMessage.details')} />
      </If>
    </Box>
  );
};
