import { keepPreviousData, Query, QueryKey } from '@tanstack/react-query';
import { FetchFilterProps } from 'services/API/machine';
import { APIError } from 'types/apiError';

export const keepPreviousDataOnRefetch = <T>(
  previousData: T | undefined,
  previousQuery: Query<T, APIError, T, QueryKey> | undefined,
  newQueryKey: FetchFilterProps
): T | undefined => {
  const queryKey = previousQuery?.queryKey[1] as {
    filters: FetchFilterProps;
  };
  const previousQueryKey = queryKey?.filters as FetchFilterProps;
  if (previousQueryKey?.id === newQueryKey.id) {
    return keepPreviousData(previousData);
  }
  return undefined;
};
