import { REFRESH_OVERALL_STATES_INTERVAL } from 'utils/constants';
import { fetchMachinesWidget } from 'services/API/machine';
import { WidgetData } from 'types/machine';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { useQueryWithError } from 'hooks/useQueryWithError';

export const useGetWidgetData = (machineId?: string) => {
  return useQueryWithError<WidgetData[]>({
    queryKey: machinesQueryKeys.machineWidget(machineId || ''),
    queryFn: async () => {
      return await fetchMachinesWidget({ id: machineId || '' });
    },
    retry: 0,
    refetchInterval: REFRESH_OVERALL_STATES_INTERVAL,
  });
};
