import React, { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';

import Box from '@mui/material/Box';

export const ClockUTC: FC = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        fontWeight: '500',
        fontSize: '20px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {format(time, 'HH:mm')}
      <Box
        sx={{
          fontWeight: '400',
          fontSize: '12px',
          color: (theme) => theme.palette.secondary.light,
          marginTop: '10px',
        }}
      >
        24:00
      </Box>
    </Box>
  );
};
