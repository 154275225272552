import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { LocalStorage } from 'services/LocalStorage';
import { useHasPermission } from 'services/Permissions/usePermissions';
import { STORE_AUTH_TOKEN } from 'utils/constants';

interface Props {
  children: ReactElement;
  scopes?: UserScopes[];
  redirectPath?: AppRoutes;
}

export const ProtectedRoute: FC<Props> = ({
  children,
  scopes = [],
  redirectPath = AppRoutes.Login,
}) => {
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);
  const canOpen = useHasPermission(scopes);

  if (!token) {
    return <Navigate to={AppRoutes.Login} replace />;
  }

  if (!canOpen) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
