import { LocalStorage } from 'services/LocalStorage';
import { CUSTOM_SENSOR_Y_AXIS } from 'utils/constants';
import { CustomYAxisRange } from 'types/sensor';

interface Result {
  yAxisRange: CustomYAxisRange | null;
  onChangeYAxisRange: (value: CustomYAxisRange | null) => void;
}

export const useSensorYAxisRange = (
  sensorChartId?: string,
  sensorTitle?: string
): Result => {
  const key = `${CUSTOM_SENSOR_Y_AXIS}${sensorChartId}${sensorTitle}`;
  const yAxisRange: CustomYAxisRange | null = LocalStorage.getItem(key);
  const onChangeYAxisRange = (value: CustomYAxisRange | null) => {
    LocalStorage.setItem(key, value);
  };

  return { yAxisRange, onChangeYAxisRange };
};
