import { isSameDay, isSameHour } from 'date-fns';

import { DateRangeType } from 'types/machine';
import { DateRanges } from 'enums/DateRanges.enum';
import { getCurrentHourByUTC, getTodayStartByUTC } from './getTimeByUTC';

interface Props {
  dateRange: DateRangeType;
  selectedDate: Date;
}
export const getIsCurrentPeriod = ({
  dateRange,
  selectedDate,
}: Props): boolean => {
  const currentDay = getTodayStartByUTC();
  const currentHour = getCurrentHourByUTC();

  return dateRange === DateRanges.HOUR
    ? isSameHour(selectedDate, currentHour)
    : isSameDay(selectedDate, currentDay);
};
