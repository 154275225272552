import React, { FC } from 'react';

interface Props {
  isSensorPage?: boolean;
}

export const ReferenceBand: FC<Props> = ({ isSensorPage }) => {
  return (
    <g>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#69B34C" />
          <stop offset="20.83%" stopColor="#ACB334" />
          <stop offset="44.27%" stopColor="#FAB733" />
          <stop offset="64.58%" stopColor="#FF8E15" />
          <stop offset="82.29%" stopColor="#FF4E11" />
          <stop offset="100%" stopColor="#FF0D0D" />
        </linearGradient>
      </defs>
      <rect
        x={isSensorPage ? 80 : 123}
        y={29}
        width={isSensorPage ? 10 : 15}
        height={231}
        fill="url(#colorUv)"
        fillOpacity={1}
      />
    </g>
  );
};
