import { useEffect, useMemo } from 'react';
import { LocalStorage } from 'services/LocalStorage';
import { STORE_ENV } from 'utils/constants';

export const useDemoEnvironment = (): boolean => {
  const isDemoEnvironment = useMemo(() => {
    const localStorageEnv = LocalStorage.getItem(STORE_ENV);
    return process.env.REACT_APP_ENV === 'demo' || localStorageEnv === 'demo';
  }, []);

  useEffect(() => {
    if (isDemoEnvironment) {
      // eslint-disable-next-line no-console
      console.log(
        '%cDEMO MODE IS ENABLED',
        'background: #222; color: #9bc26e; border-radius: 10px; padding: 10px;'
      );
    }
  }, [isDemoEnvironment]);

  return isDemoEnvironment;
};
