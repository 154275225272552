import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { GeneralOverviewChart } from 'views/ChartView/GeneralOverviewChart';
import { ModelSensorChart } from 'views/ChartView/SensorChart';
import { SensorSensitivitySettings } from 'views/ChartView/SensorChart/SensorSensivitySettings';

import { PermissionsGate } from 'components/PermissionsGate';

import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import { DateRanges } from 'enums/DateRanges.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { fetchSensorData } from 'services/API/sensor';
import { FetchFilterProps } from 'services/API/machine';

import { getCustomTimeRangeQuery, getTimeRangeQuery } from 'utils/helpers';

import { SensorReadingResponse } from 'types/sensor';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useLiveUpdateChartOptions } from 'hooks/ChartData/useLiveUpdateChartOptions';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { Flex, Spinner } from '@radix-ui/themes';

export const ModelPage: FC = () => {
  const { id } = useParams();
  const [sensorDataSet, setSensorDataSet] =
    useState<SensorReadingResponse | null>(null);
  const { selectedDate, dateRange, customStartDate, customEndDate } =
    useChartCacheData();
  const [isLiveUpdate] = useLiveUpdateChartOptions();

  const options: FetchFilterProps = {
    timeRangeQuery:
      DateRanges.CUSTOM === dateRange
        ? getCustomTimeRangeQuery(customStartDate, customEndDate)
        : getTimeRangeQuery(dateRange, selectedDate),
    id,
  };

  const defaultOptions: FetchFilterProps = {
    timeRangeQuery: getTimeRangeQuery(DateRanges.HOUR, selectedDate),
    id,
  };

  const { data } = useQueryWithError<SensorReadingResponse>({
    queryKey: sensorQueryKeys.filteredModelSensorData(defaultOptions),
    queryFn: () => fetchSensorData(defaultOptions),
    retry: 0,
  });

  useEffect(() => {
    if (data) {
      setSensorDataSet(data);
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <GeneralOverviewChart
        options={options}
        liveUpdate={isLiveUpdate}
        additionalSettings={
          <PermissionsGate scopes={[UserScopes.UpdateSensitivity]}>
            <SensorSensitivitySettings id={id || ''} />
          </PermissionsGate>
        }
        isSensorPage
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {sensorDataSet ? (
          Object.keys(sensorDataSet).map((key) => (
            <ModelSensorChart
              dataKey={key}
              key={`${sensorDataSet[key].order}${sensorDataSet[key].title}`}
              options={options}
              start={new Date(selectedDate.toISOString()).getTime()}
              liveUpdate={isLiveUpdate}
            />
          ))
        ) : (
          <Flex justify="center" width="100%" p="2">
            <Spinner size="3" />
          </Flex>
        )}
      </Box>
    </Box>
  );
};
