import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { deleteEvent } from 'services/API/event';
import { queryClient } from 'index';
import { eventsQueryKeys } from 'enums/EventsQueryKeys.enum';
import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { TrashIcon } from '@radix-ui/react-icons';
import { AlertDialog, Flex, IconButton, Button } from '@radix-ui/themes';
import { useMutationWithError } from 'hooks/useMutationWithError';

export const DeleteMenu: FC<{ eventId: string }> = ({ eventId }) => {
  const { t } = useTranslation();

  const { mutate: deleteEventById } = useMutationWithError<null, string>({
    mutationFn: deleteEvent,
    onSuccess() {
      toast.success(t('toast.eventDeleted'));
      queryClient.invalidateQueries({ queryKey: eventsQueryKeys.events });
      queryClient.invalidateQueries({
        queryKey: machinesQueryKeys.machineEvents,
      });
    },
  });

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        <IconButton size="1" variant="ghost">
          <TrashIcon />
        </IconButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title>{t('events.deleteTitle')}</AlertDialog.Title>
        <AlertDialog.Description size="2">
          {t('events.deleteDescription')}
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="soft" color="gray">
              {t('forms.cancel')}
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="solid"
              color="red"
              onClick={() => deleteEventById(eventId)}
            >
              {t('forms.delete')}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
