import React, { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Sidebar } from 'components/Sidebar';
import { If } from 'components/If';
import { useAuth } from 'hooks/Auth/useAuth';
import { useHasPermission } from 'services/Permissions/usePermissions';
import { UserScopes } from 'enums/UserScopes.enum';
import { Grid, Flex } from '@radix-ui/themes';

interface Props {
  children: ReactNode;
  loading?: boolean;
}

export const Layout: FC<Props> = ({ children, loading = false }) => {
  const { tenantId } = useAuth();
  const isLoadingTenants = useHasPermission([UserScopes.TenantSwitcher])
    ? !tenantId
    : false;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.palette.custom.backgroundColor,
        minHeight: '100vh',
      }}
    >
      {loading ||
        (isLoadingTenants && (
          <Box
            sx={{
              position: 'fixed',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '64px',
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <CircularProgress color="primary" size={50} />
          </Box>
        ))}
      <Grid columns="210px 1fr">
        <Box />
        <Flex direction="column">
          <Box sx={{ minHeight: 'calc(100vh - 42px)' }}>
            <Header />
            <Box sx={{ padding: '24px' }}>
              <If condition={!isLoadingTenants}>
                {children || 'Data is not loaded, sorry'}
              </If>
            </Box>
          </Box>
          <Footer />
        </Flex>
      </Grid>
      <Sidebar />
    </Box>
  );
};
