import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import { DateRanges } from 'enums/DateRanges.enum';
import { getIsCurrentPeriod } from 'utils/helpers';
import { differenceInDays, format } from 'date-fns';
import { Languages } from 'enums/Languages.enum';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { FetchFilterProps } from 'services/API/machine';

interface OverallStatesChartProps {
  options: FetchFilterProps;
}

const centerText: CSSProperties = {
  textAlign: 'center',
  fontSize: '14px',
  color: theme.secondaryFontColor,
};

export const OverallChartDateLabel: FC<OverallStatesChartProps> = ({
  options,
}) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;
  const { selectedDate, dateRange } = useChartCacheData();

  const { start, end } = options.timeRangeQuery;

  const startDate = new Date(start as string);
  const endDate = new Date(end as string);

  const isCurrentPeriod =
    DateRanges.CUSTOM !== dateRange &&
    getIsCurrentPeriod({
      dateRange,
      selectedDate,
    });

  const formatTime = (date: Date) => format(date, 'HH:mm', { locale });
  const formatFullDate = (date: Date) =>
    format(date, 'd LLL yy HH:mm', { locale });
  const dateRangeFormat =
    differenceInDays(endDate, startDate) >= 1 ? formatFullDate : formatTime;

  if (isCurrentPeriod) {
    return (
      <div style={centerText}>
        <span>{dateRangeFormat(startDate)}</span>
        <br />-<br />
        <span>{t('charts.now').toLocaleLowerCase()}</span>
      </div>
    );
  }

  return (
    <div style={centerText}>
      <span>{dateRangeFormat(startDate)}</span>
      <br />-<br />
      <span>{dateRangeFormat(endDate)}</span>
    </div>
  );
};
