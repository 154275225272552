import React from 'react';
import {
  PickersCalendarHeader,
  PickersCalendarHeaderProps,
} from '@mui/x-date-pickers';
import { theme } from 'styles/theme';

export const CalendarHeader = (props: PickersCalendarHeaderProps<Date>) => {
  const styles = {
    borderBottom: `1px solid ${theme.borderColor}`,
    height: '54px',
  };

  return (
    <div style={{ position: 'relative', ...styles }}>
      <PickersCalendarHeader {...props} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: '-57px',
          width: '56px',
          backgroundColor: 'white',
          borderLeft: `1px solid ${theme.borderColor}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...styles,
        }}
      >
        hh
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: '-113px',
          width: '56px',
          backgroundColor: 'white',
          borderLeft: `1px solid ${theme.borderColor}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...styles,
        }}
      >
        mm
      </div>
    </div>
  );
};
