import React, { forwardRef, ElementRef } from 'react';
import { Badge, BadgeProps } from '@radix-ui/themes';
import { EventTypes, StateColors } from 'enums/EventTypes.enum';

interface Props extends BadgeProps {
  score: number | 'NaN';
}

export const HealthScoreChip = forwardRef<ElementRef<'span'>, Props>(
  ({ score, ...props }, forwardedRef) => {
    let state = EventTypes.INACTIVE;
    if (score === 'NaN') {
      state = EventTypes.INACTIVE;
    } else if (score > 60) {
      state = EventTypes.ACTIVE;
    } else if (score > 30) {
      state = EventTypes.WARNING;
    } else {
      state = EventTypes.ALARM;
    }

    return (
      <Badge
        ref={forwardedRef}
        size="3"
        color={StateColors[state] as BadgeProps['color']}
        {...props}
      >
        {state === EventTypes.INACTIVE ? '-' : `${score}%`}
      </Badge>
    );
  }
);
