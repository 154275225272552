import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { theme as colors } from 'styles/theme';

export const BasicTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow={props.arrow ?? true}
    placement={props.placement || 'top'}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.tooltipBackgroundColor,
    padding: theme.spacing(1.5),
  },
}));
