import { useQueryClient } from '@tanstack/react-query';
import { healthQueryKeys } from 'enums/HealthQueryKeys.enum';
import { useAbortQueryOnCleanup } from 'hooks/useAbortQueryOnCleanup';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { useCallback, useEffect, useMemo } from 'react';
import { fetchAllHealth, FetchFilterProps } from 'services/API/machine';
import { MachineHealthData, MachineTree } from 'types/machine';
import { AllHealthResponse } from 'types/models';
import { SMOOTHING_WINDOW } from 'utils/constants';
import {
  getAllComponents,
  getAllModels,
} from 'utils/helpers/filterMachineTree';
import { keepPreviousDataOnRefetch } from 'utils/helpers/queryHelpers';

const getMedian = (values: number[]) => {
  return values.sort((a, b) => a - b)[Math.floor(values.length / 2)];
};

export const useGetAggregatedHealthData = ({
  machineTree,
  options,
}: {
  machineTree: MachineTree | undefined;
  options: FetchFilterProps;
}) => {
  const queryClient = useQueryClient();

  const {
    data: allHealth,
    isSuccess,
    ...rest
  } = useQueryWithError<AllHealthResponse>({
    queryKey: healthQueryKeys.filteredHealth(options),
    queryFn: ({ signal }) => fetchAllHealth(options, signal),
    retry: 1,
    enabled: !!machineTree,
    placeholderData: (previousData, previousQuery) =>
      keepPreviousDataOnRefetch(previousData, previousQuery, options),
  });

  const persistChildrenComponentsModels = useCallback(
    (machineTree: MachineTree) => {
      const allComponents = getAllComponents(machineTree);
      allComponents.forEach((component) => {
        const allModels = getAllModels(component);
        const allModelsIds = allModels.map((model) => model.id);
        const modelsQueryKey = healthQueryKeys.filteredHealth({
          timeRangeQuery: options.timeRangeQuery,
          id: component.id,
        });
        queryClient.setQueryData(
          modelsQueryKey,
          allHealth?.filter((item) => allModelsIds.includes(item.id))
        );
      });
    },
    [allHealth, options.timeRangeQuery, queryClient]
  );

  useAbortQueryOnCleanup(healthQueryKeys.filteredHealth(options));

  useEffect(() => {
    if (isSuccess && machineTree) {
      persistChildrenComponentsModels(machineTree);
    }
  }, [isSuccess, machineTree, persistChildrenComponentsModels]);

  const timesteps = useMemo(
    () =>
      new Set(
        allHealth?.flatMap((item) => item.health_score).map((item) => item.date)
      ),
    [allHealth]
  );

  const aggregatedHealthData = useMemo(
    () =>
      Array.from(timesteps).map((timestamp) => {
        const healthObservations =
          allHealth
            ?.flatMap((item) => item.health_score)
            .filter((item) => item.date === timestamp)
            .filter((item) => item.value !== null)
            .map((item) => item.value) || [];

        const healthAggregated = healthObservations.reduce((acc, curr) => {
          if (curr === null) {
            return acc;
          }
          return Math.min(acc || curr, curr);
        }, null);

        if (healthAggregated === null) {
          return { date: timestamp, health: null };
        }

        return { date: timestamp, health: healthAggregated };
      }),
    [allHealth, timesteps]
  );

  const averagedHealthData: MachineHealthData[] = useMemo(
    () =>
      // Todo: add data observations to start and end of aggregatedHealthData to avoid artefacts

      aggregatedHealthData.map(({ date, health }, index, array) => {
        if (health === null) {
          return { date, health: 'NaN' };
        }

        const smoothing = (array.length / 100) * SMOOTHING_WINDOW;

        const start = Math.max(0, index - smoothing + 1);
        const end = index + 1;
        const window = array.slice(start, end);
        const healthValues = window.map((item) => item.health);
        const nonNullHealthValues = healthValues.filter(
          (a) => a !== null
        ) as number[];
        const windowMedian = getMedian(nonNullHealthValues);

        if (windowMedian === null) {
          return { date, health: 'NaN' };
        }

        const percentagedMedian = Math.round(windowMedian * 100);
        return { date, health: percentagedMedian };
      }),
    [aggregatedHealthData]
  );

  return { data: averagedHealthData, isSuccess, ...rest };
};
