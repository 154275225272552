import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import Box from '@mui/material/Box';

import {
  MAX_Y_AXIS,
  MIN_Y_AXIS,
  REFRESH_GENERAL_OVERVIEW_DEFAULT,
} from 'utils/constants';

import { fetchMachineEvents, TimeRangeQuery } from 'services/API/machine';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';
import { Languages } from 'enums/Languages.enum';

import { useGeneralChartData } from 'hooks/ChartData/useGeneralChartData';

import { DateRangeType } from 'types/machine';
import { CustomerEvent } from 'types/event';

import { keepPreviousDataOnRefetch } from 'utils/helpers/queryHelpers';
import { theme } from 'styles/theme';
import { format, getTime, isAfter, parse, subDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import { Skeleton } from '@radix-ui/themes';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { useFindMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { useGetAggregatedHealthData } from 'hooks/Chart/useGetAggregatedHealthData';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useAbortQueryOnCleanup } from 'hooks/useAbortQueryOnCleanup';

type TableLightChartProps = {
  dateRange: DateRangeType;
  itemId?: string;
  isShowTicks?: boolean;
};

const CHART_TICKS = [
  '00:00',
  '02:00',
  '04:00',
  '06:00',
  '08:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
];

const getTickTimeStamp = () =>
  CHART_TICKS.map((tick: string) => {
    const tickDate = parse(tick, 'HH:mm', new Date());
    return isAfter(new Date(), tickDate)
      ? getTime(tickDate)
      : getTime(subDays(tickDate, 1));
  }).sort();

export const DashboardTableChart: FC<TableLightChartProps> = ({
  dateRange,
  itemId,
  isShowTicks,
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;
  const { selectedDate } = useChartCacheData();

  const timeRangeQuery: TimeRangeQuery = {
    start: subDays(selectedDate, 1).toISOString(),
    end: selectedDate.toISOString(),
  };
  const options = {
    id: itemId || '',
    timeRangeQuery,
  };

  const { data: machineTree } = useFindMachineTree(options.id);

  const currentMachineTree = machineTree?.[0];

  const { data: healthData, isPending } = useGetAggregatedHealthData({
    options,
    machineTree: currentMachineTree,
  });

  const ticks = getTickTimeStamp();

  const { data: eventsData } = useQueryWithError<CustomerEvent[]>({
    queryKey: machinesQueryKeys.filteredMachineEvents(options),
    queryFn: () => fetchMachineEvents(options),
    retry: 0,
    refetchInterval: REFRESH_GENERAL_OVERVIEW_DEFAULT,
    enabled: !!itemId,
    placeholderData: (previousData, previousQuery) =>
      keepPreviousDataOnRefetch(previousData, previousQuery, options),
  });

  useAbortQueryOnCleanup(machinesQueryKeys.filteredMachineEvents(options));

  const generalChartData = useGeneralChartData(
    dateRange,
    healthData,
    eventsData
  );

  if (isPending) {
    return <Skeleton width="100%" height="64px" />;
  }

  return (
    <Box>
      <ResponsiveContainer
        width="99%"
        height={isShowTicks ? 84 : 64}
        maxHeight={64}
      >
        <ComposedChart
          data={generalChartData}
          margin={{
            top: 1,
            right: 1,
            left: -40,
            bottom: isShowTicks ? -10 : -29,
          }}
        >
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tickCount={12}
            ticks={ticks}
            type="number"
            scale="time"
            domain={['auto', 'auto']}
            minTickGap={0}
            tickSize={1}
            tickFormatter={(tick) =>
              format(new Date(tick), 'HH:mm', { locale })
            }
          />
          <YAxis
            dataKey="health"
            axisLine={false}
            tickLine={false}
            domain={[MIN_Y_AXIS, MAX_Y_AXIS]}
            tick={false}
          />
          <CartesianGrid opacity={0.7} horizontal={false} />
          {eventsData?.map((item) => {
            return (
              <Area
                type="step"
                dataKey={item.id}
                fill={item.type.hexcolor}
                fillOpacity={0.1}
                strokeWidth={0}
                activeDot={false}
                key={item.id}
                id={item.id}
                isAnimationActive={false}
              />
            );
          })}
          <Line
            type="linear"
            dataKey="health"
            stroke={theme.lightBlue}
            fillOpacity={0}
            strokeWidth={2}
            activeDot={false}
            dot={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
