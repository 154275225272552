import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRoles, UserRolesLabels } from 'enums/UserRoles.enum';

interface UserMenuProps {
  roles: UserRoles[];
}

export const UserRolesCell: FC<UserMenuProps> = ({ roles }) => {
  const { t } = useTranslation();

  return (
    <div>
      {roles.map((role) => t(`${UserRolesLabels[role]}.title`)).join(', ')}
    </div>
  );
};
