import React, { FC } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { theme } from 'styles/theme';
import { Rectangle } from 'recharts';

interface Props {
  payload?: Payload<string, string>[];
  height?: number;
  points?: { x: number; y: number }[];
  pointerEvents?: string;
}

export const ChartCursor: FC<Props> = ({
  payload: payloads,
  pointerEvents,
  height,
  points,
}) => {
  if (!payloads?.length || !points?.length) {
    return null;
  }
  const { x, y } = points[0];
  const { showTooltip } = payloads[0].payload;

  return showTooltip ? (
    <Rectangle
      stroke={theme.secondaryFontColor}
      strokeWidth={1}
      x={x}
      y={y}
      fillOpacity={0}
      pointerEvents={pointerEvents}
      width={1}
      height={height}
      points={points as unknown as string}
      type="linear"
    />
  ) : null;
};
