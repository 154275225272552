import { set, subDays, subHours } from 'date-fns';

import { DateRangeType } from 'types/machine';
import { DateRanges } from 'enums/DateRanges.enum';

interface Props {
  dateRange: DateRangeType;
  selectedDate: Date;
  isCurrentPeriod?: boolean;
}

export const getPrevRange = ({
  dateRange,
  selectedDate,
  isCurrentPeriod,
}: Props): Date => {
  switch (dateRange) {
    case DateRanges.HOUR: {
      return isCurrentPeriod
        ? set(subHours(selectedDate, 1), { minutes: 59, seconds: 59 })
        : subHours(selectedDate, 1);
    }
    case DateRanges.WEEK: {
      return set(subDays(selectedDate, 7), {
        hours: 12,
        minutes: 0,
        seconds: 0,
      });
    }
    default: {
      return set(subDays(selectedDate, 1), {
        hours: 12,
        minutes: 0,
        seconds: 0,
      });
    }
  }
};
