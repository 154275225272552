import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

interface Props {
  message?: string;
}

export const EmptyMessage: FC<Props> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        margin: '80px auto',
        fontSize: '14px',
        color: (theme) => theme.palette.custom.secondaryFontColor,
        textAlign: 'center',
      }}
    >
      {message || t('emptyMessage.default')}
    </Box>
  );
};
