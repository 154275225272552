import { FetchFilterProps } from 'services/API/machine';
import {
  FetchSensorProps,
  SearchIdPaginationFilters,
} from 'services/API/sensor';

export const sensorQueryKeys = {
  sensor: ['sensor'],
  modelSensorData: ['modelSensorData'],
  sensorData: ['sensorData'],
  sensitivity: ['sensitivity'],
  sensorWithTimeseries: ['sensorWithTimeseries'],
  filteredModelSensorData: (
    filters: FetchFilterProps
  ): (string | { filters: FetchFilterProps })[] => [
    ...sensorQueryKeys.modelSensorData,
    { filters },
  ],
  filteredSensorData: (
    filters: FetchFilterProps
  ): (string | { filters: FetchFilterProps })[] => [
    ...sensorQueryKeys.sensorData,
    { filters },
  ],
  getSensor: (
    filters: FetchSensorProps
  ): (string | { filters: FetchSensorProps })[] => [
    ...sensorQueryKeys.sensorWithTimeseries,
    { filters },
  ],
  sensitivityPath: (id: string): string[] => [
    ...sensorQueryKeys.sensorData,
    ...sensorQueryKeys.sensitivity,
    id,
  ],
  filteredSensors: (
    filters: SearchIdPaginationFilters
  ): (string | { filters: SearchIdPaginationFilters })[] => [
    ...sensorQueryKeys.sensor,
    { filters },
  ],
};
