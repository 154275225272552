import * as queryString from 'query-string';

import { api } from 'services/API/client';

import { Machine, MachineTree, WidgetData } from 'types/machine';
import { CustomerEvent } from 'types/event';
import { AllHealthResponse } from 'types/models';

export interface PaginationFilters {
  offset: number;
  limit: number;
}

export interface MachinesFullData {
  data: Machine[];
  meta: {
    count: number;
  };
}
export interface TimeRangeQuery {
  end?: string;
  start?: string;
}

export interface FetchFilterProps {
  timeRangeQuery: TimeRangeQuery;
  id?: string;
}

export interface FetchWidgetsProps {
  id: string;
}

export const fetchMachines = async (
  machinesQuery: PaginationFilters
): Promise<MachinesFullData> => {
  const query = queryString.stringify(machinesQuery);

  const { data } = await api.get(`/machines?${query}`);

  return data;
};

export const fetchAllMachinesParts = async (): Promise<MachineTree[]> => {
  const {
    data: { data },
  } = await api.get(`/machines/list`);

  return data;
};

export const fetchAllHealth = async (
  props: FetchFilterProps,
  signal?: AbortSignal
): Promise<AllHealthResponse> => {
  const { timeRangeQuery, id } = props;
  const query = queryString.stringify(timeRangeQuery);

  if (!id) return [];

  const {
    data: { data },
  } = await api.get(`/machines/${id}/models-health?${query}`, {
    signal,
  });

  return data;
};

export const fetchMachineEvents = async (
  props: FetchFilterProps,
  signal?: AbortSignal
): Promise<CustomerEvent[]> => {
  const { timeRangeQuery, id } = props;
  const query = queryString.stringify(timeRangeQuery);

  if (!id) return [];

  const {
    data: { data },
  } = await api.get(`/machines/${id}/state?${query}`, { signal });

  return data;
};

export const fetchMachinesWidget = async (
  props: FetchWidgetsProps
): Promise<WidgetData[]> => {
  const { id } = props;

  if (!id) return [];

  const {
    data: { data },
  } = await api.get(`/machines/${id}/widgets`);

  return data;
};
