import { createTheme } from '@mui/material/styles';
import { theme } from 'styles/theme';

const DRAWER_WIDTH = 424;

export const muiTheme = createTheme({
  typography: {
    fontFamily: theme.primaryFont,
    fontSize: 14,
    allVariants: {
      color: theme.primaryFontColor,
    },
  },
  spacing: 4,
  palette: {
    primary: {
      main: theme.blue,
    },
    secondary: {
      main: theme.secondaryFontColor,
      light: theme.lightFontColor,
    },
    error: {
      main: theme.red,
    },
    warning: {
      main: theme.orange,
    },
    success: {
      main: theme.green,
    },
    info: {
      main: theme.gray,
    },
    custom: {
      white: theme.white,
      tooltipBackground: theme.tooltipBackgroundColor,
      borderColor: theme.borderColor,
      hoverColor: theme.hoverColor,
      primaryFontColor: theme.primaryFontColor,
      secondaryFontColor: theme.secondaryFontColor,
      backgroundColor: theme.backgroundColor,
      transparentWhite: theme.transparentWhite,
      orange: theme.orange,
      green: theme.green,
      greenLight: theme.greenLight,
      greenHover: theme.greenHover,
      greenText: theme.greenText,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textDecoration: 'uppercase',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: DRAWER_WIDTH,
          maxHeight: 'inherit',
          boxSizing: 'border-box',
          backgroundColor: theme.white,
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
});
