export enum EventTypes {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALARM = 'error',
  WARNING = 'warning',
}

export const StateColors = {
  [EventTypes.ACTIVE]: 'green',
  [EventTypes.INACTIVE]: 'gray',
  [EventTypes.ALARM]: 'red',
  [EventTypes.WARNING]: 'yellow',
};
