import { tenantsQueryKeys } from 'enums/TenantsQueryKeys.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { getTenants } from 'services/API/tenant';
import { useHasPermission } from 'services/Permissions/usePermissions';
import { Tenant } from 'types/tenant';
import { useQueryWithError } from 'hooks/useQueryWithError';

export const useTenants = () => {
  return useQueryWithError<Tenant[]>({
    queryKey: tenantsQueryKeys.tenants,
    queryFn: getTenants,
    enabled: useHasPermission([
      UserScopes.SelectUserTenant,
      UserScopes.TenantSwitcher,
    ]),
  });
};
