import React from 'react';
import { add, isWithinInterval, set, sub } from 'date-fns';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { theme } from 'styles/theme';

enum Type {
  minutes = 'minutes',
}

export const DigitalClockItem = (
  props: MenuItemProps & { trainingTimes?: [Date, Date][]; selectedDate?: Date }
) => {
  const {
    trainingTimes = [],
    selectedDate,
    selected,
    'aria-label': ariaLabel,
    ...other
  } = props;

  const [value, type] = (ariaLabel || '').split(' ');
  const date = set(selectedDate || new Date(), { [type]: +value, seconds: 0 });
  const isMinutesView = type === Type.minutes;

  const isHighlighted = trainingTimes.some(([start, end]: [Date, Date]) =>
    isWithinInterval(date, {
      start,
      end,
    })
  );

  const isMiddleRangeDate = isHighlighted
    ? trainingTimes.some(([start, end]: [Date, Date]) =>
        isWithinInterval(new Date(date), {
          start: add(start, { [type]: isMinutesView ? 5 : 1 }),
          end: sub(end, { [type]: isMinutesView ? 5 : 1 }),
        })
      )
    : false;
  const isStartRangeDate =
    isHighlighted && !isMiddleRangeDate
      ? trainingTimes.some(([start]: [Date, Date]) =>
          isWithinInterval(new Date(date), {
            start,
            end: add(start, { [type]: isMinutesView ? 5 : 1 }),
          })
        )
      : false;
  const isEndRangeDate =
    isHighlighted && !isMiddleRangeDate
      ? trainingTimes.some(([, end]: [Date, Date]) =>
          isWithinInterval(new Date(date), {
            start: sub(end, { [type]: isMinutesView ? 5 : 1 }),
            end,
          })
        )
      : false;

  const highlightedStyles = {
    borderBottomLeftRadius: isEndRangeDate ? '10px' : '0',
    borderBottomRightRadius: isEndRangeDate ? '10px' : '0',
    borderTopRightRadius: isStartRangeDate ? '10px' : '0',
    borderTopLeftRadius: isStartRangeDate ? '10px' : '0',
    backgroundColor: theme.greenLight,
    color: theme.green,
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={
          selected
            ? {
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                border: `1px solid ${theme.lightFontColor}`,
                borderRadius: '10px',
                zIndex: 10,
              }
            : {}
        }
      />
      <MenuItem
        {...other}
        selected={isHighlighted}
        style={{
          display: 'flex',
          justifyContent: 'center',
          ...(isHighlighted ? highlightedStyles : {}),
        }}
      />
    </div>
  );
};
