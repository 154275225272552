import React, { FC } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';

import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getUTCDateForView } from 'utils/helpers';

import { Languages } from 'enums/Languages.enum';
import { DatePickerSlotsComponentsProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import { TextFieldProps } from '@mui/material';

interface EventsDrawerDataPickerProps {
  setNewDate: (date: Date) => void;
  value: Date;
  labelStyles?: { [key: string]: string };
  disabled?: boolean;
  maxDate?: Date;
}

interface CustomTextFieldProps {
  labelStyles?: { [key: string]: string };
}

const TextField = (props: TextFieldProps & CustomTextFieldProps) => {
  const { InputProps, label, labelStyles } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      ref={InputProps?.ref}
    >
      <Box
        sx={{
          fontWeight: 500,
          fontSize: '20px',
          padding: '6px 0',
          color: (theme) => theme.palette.secondary.main,
          ...labelStyles,
        }}
      >
        {label}
      </Box>
      {InputProps?.endAdornment}
    </Box>
  );
};

export const DataPicker: FC<EventsDrawerDataPickerProps> = ({
  setNewDate,
  value,
  labelStyles,
  disabled = false,
  maxDate,
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;

  const label = format(
    getUTCDateForView(value) || new Date(),
    'iii, d LLL yyyy',
    { locale }
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DatePicker
        disabled={disabled}
        value={value}
        maxDate={maxDate}
        onChange={(newValue) => {
          if (newValue) {
            newValue?.setUTCHours(0, 0, 0, 0);
            setNewDate(newValue);
          }
        }}
        slotProps={
          {
            textField: {
              label,
              labelStyles,
            },
          } as DatePickerSlotsComponentsProps<Date>
        }
        slots={{
          textField: TextField,
          openPickerIcon: ArrowDropDownIcon,
        }}
      />
    </LocalizationProvider>
  );
};
