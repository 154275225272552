import * as queryString from 'query-string';
import { api } from 'services/API/client';
import { Detail } from 'types/detail';

interface DetailsQueryFilters {
  id: string;
  offset: number;
  limit: number;
}

export interface DetailsFullData {
  data: Detail[];
  meta: {
    count: number;
  };
}

export const fetchDetails = async ({
  id,
  ...detailsQuery
}: DetailsQueryFilters): Promise<DetailsFullData> => {
  const query = queryString.stringify(detailsQuery);

  const { data } = await api.get(`/machines/${id}?${query}`);

  return data;
};
