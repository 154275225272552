import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

import { SortingOrder, SortingType } from 'hooks/Table/useTableSorting';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { If } from 'components/If';
import { Box } from '@mui/material';
import { Spinner } from '@radix-ui/themes';

export type Data = object & {
  id: string;
};

interface ColumnsSettings {
  isLastItem?: boolean;
}

export interface Column<T> {
  id: string;
  label: string;
  width?: number;
  minWidth?: number;
  cellPadding?: string;
  align?: TableCellProps['align'];
  sx?: TableCellProps['sx'];
  format?: (value: T, settings?: ColumnsSettings) => string | ReactElement;
  sortingType?: SortingType;
}

interface Props<T extends Data> {
  columns: Column<T>[];
  data: T[];
  pagination?: ReactNode;
  onClickByRow?: (value: T) => void;
  onClickByColumnHead?: (value: SortingType) => void;
  sortingOrder?: SortingOrder;
  sortingType?: SortingType;
  loading?: boolean;
}

const spinnerBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
};

export const BasicTable = <T extends Data>({
  columns,
  data,
  pagination,
  onClickByRow,
  onClickByColumnHead,
  sortingOrder,
  sortingType,
  loading,
}: Props<T>) => {
  const { t } = useTranslation();
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 'fit-content',
        boxShadow: 'none',
        border: '1px solid',
        borderColor: (theme) => theme.palette.custom.borderColor,
      }}
    >
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((item) => {
              return (
                <TableCell
                  key={item.id}
                  style={{
                    width: item.width,
                    minWidth: item.minWidth,
                    cursor: item.sortingType ? 'pointer' : 'unset',
                  }}
                  align={item.align || 'left'}
                  sx={{
                    fontSize: '14px',
                    padding: '5px 16px',
                  }}
                  onClick={() => {
                    return onClickByColumnHead && item.sortingType
                      ? onClickByColumnHead(item.sortingType)
                      : null;
                  }}
                >
                  {t(item.label)}
                  {item.sortingType === sortingType &&
                    (sortingOrder && sortingOrder === SortingOrder.DESC ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    ))}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow
                tabIndex={-1}
                key={row.id}
                onClick={onClickByRow && (() => onClickByRow(row))}
                sx={{
                  cursor: onClickByRow ? 'pointer' : 'auto',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.custom.hoverColor,
                  },
                }}
              >
                {columns.map((column) => {
                  const value = row[column.id];
                  const isLastItem = data.length - 1 === index;

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align || 'left'}
                      sx={{ padding: column.cellPadding, ...column.sx }}
                    >
                      {column.format
                        ? column.format(row, { isLastItem })
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <If condition={!!loading && !data.length}>
        <Box sx={spinnerBoxStyles}>
          <Spinner />
        </Box>
      </If>
      {pagination}
    </TableContainer>
  );
};
