import React, { FC } from 'react';
import { Text } from '@radix-ui/themes';

interface ErrorMessageProps {
  error?: {
    message?: string;
  };
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
  return error ? <Text size="2">{error?.message}</Text> : null;
};
