import React from 'react';
import {
  addDays,
  endOfDay,
  isSameDay,
  isWithinInterval,
  startOfDay,
  subDays,
} from 'date-fns';
import { styled } from '@mui/material/styles';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { theme } from 'styles/theme';

const HighlightedDay = styled(PickersDay<Date>)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.custom.greenLight} !important`,
    color: theme.palette.custom.green,
    margin: 0,
    width: '40px',

    '&:hover': {
      backgroundColor: theme.palette.custom.greenLight,
    },
  },
}));

export const DayItem = (
  props: PickersDayProps<Date> & { trainingTimes?: [Date, Date][] }
) => {
  const {
    trainingTimes = [],
    selected,
    day,
    outsideCurrentMonth,
    ...other
  } = props;
  const date = new Date(day);

  const isHighlighted =
    !outsideCurrentMonth &&
    trainingTimes.some(([start, end]: [Date, Date]) =>
      isWithinInterval(date, {
        start: startOfDay(start),
        end: endOfDay(end),
      })
    );

  const isMiddleRangeDate = isHighlighted
    ? trainingTimes.some(([start, end]: [Date, Date]) =>
        isWithinInterval(date, {
          start: startOfDay(addDays(start, 1)),
          end: endOfDay(subDays(end, 1)),
        })
      )
    : false;
  const isStartRangeDate =
    isHighlighted && !isMiddleRangeDate
      ? trainingTimes.some(([start]: [Date, Date]) => isSameDay(date, start))
      : false;
  const isEndRangeDate =
    isHighlighted && !isMiddleRangeDate
      ? trainingTimes.some(([, end]: [Date, Date]) => isSameDay(date, end))
      : false;

  const highlightedStyles = {
    borderRadius: isMiddleRangeDate ? '0' : '50%',
    borderBottomLeftRadius: isStartRangeDate ? '50%' : '0',
    borderTopLeftRadius: isStartRangeDate ? '50%' : '0',
    borderBottomRightRadius: isEndRangeDate ? '50%' : '0',
    borderTopRightRadius: isEndRangeDate ? '50%' : '0',
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={
          selected
            ? {
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '2px',
                right: '2px',
                border: `1px solid ${theme.lightFontColor}`,
                borderRadius: '50%',
                zIndex: 10,
              }
            : {}
        }
      />
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isHighlighted}
        sx={isHighlighted ? highlightedStyles : {}}
      />
    </div>
  );
};
