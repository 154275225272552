import { FC, ReactElement } from 'react';
import { UserScopes } from 'enums/UserScopes.enum';
import { useHasPermission } from 'services/Permissions/usePermissions';

interface Props {
  scopes: UserScopes[];
  children: ReactElement;
}

export const PermissionsGate: FC<Props> = ({ children, scopes }) => {
  const hasPermission = useHasPermission(scopes);

  return hasPermission ? children : null;
};
