import { useQuery } from '@tanstack/react-query';
import { userQueryKeys } from 'enums/UserQueryKeys.enum';
import { useAuth } from 'hooks/Auth/useAuth';
import { useEffect } from 'react';
import { loadMe } from 'services/API/user';
import { LocalStorage } from 'services/LocalStorage';
import { User } from 'types/user';
import { STORE_AUTH_TOKEN } from 'utils/constants';

export const useMe = () => {
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);
  const { userData, onChangeUserData, logOut } = useAuth();
  const { isSuccess, isError, data, ...rest } = useQuery<User, Error>({
    queryKey: userQueryKeys.userData,
    queryFn: loadMe,
    retry: 0,
    enabled: !!token && !userData,
  });

  useEffect(() => {
    if (isError) {
      logOut();
    }
  }, [isError, logOut]);

  useEffect(() => {
    if (isSuccess) {
      onChangeUserData(data);
    }
  }, [isSuccess, onChangeUserData, data]);

  return { isSuccess, isError, data, ...rest };
};
