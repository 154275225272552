import React from 'react';
import { SegmentedControl, Text } from '@radix-ui/themes';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';

interface IProps {
  page: number;
  count: number;
  onChange: (val: number) => void;
}

const GROUP_MAX = 3;
const half = Math.ceil(GROUP_MAX / 2);

export const Pagination = (props: IProps) => {
  const { page, count, onChange } = props;

  const getButton = (current: number) => (
    <SegmentedControl.Item value={current.toString()}>
      {current}
    </SegmentedControl.Item>
  );

  return (
    <SegmentedControl.Root
      value={page.toString()}
      onValueChange={(val) => {
        const desiredPage = parseInt(val, 10);
        if (desiredPage > 0 && desiredPage <= count) onChange(desiredPage);
      }}
    >
      {count > 1 && (
        <SegmentedControl.Item
          value={(page - 1).toString()}
          aria-disabled={page === 1}
        >
          <ChevronLeftIcon />
        </SegmentedControl.Item>
      )}
      {count <= GROUP_MAX + 2 ? (
        Array(count)
          .fill(0)
          .map((item, index) => getButton(index + 1))
      ) : (
        <>
          {getButton(1)}
          {page > 1 + half && <Text>...</Text>}
          {Array(GROUP_MAX)
            .fill(0)
            .map((item, index) => {
              const p = page - half + index + 1;
              return p > 1 && p < count ? getButton(p) : null;
            })}
          {page < count - half && <Text>...</Text>}
          {getButton(count)}
        </>
      )}
      {count > 1 && (
        <SegmentedControl.Item
          value={(page + 1).toString()}
          aria-disabled={page === count}
        >
          <ChevronRightIcon />
        </SegmentedControl.Item>
      )}
    </SegmentedControl.Root>
  );
};
