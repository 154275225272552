import React, { useEffect, useState } from 'react';
import {
  Link,
  Heading,
  Spinner,
  Flex,
  TextField,
  Select,
  Text,
  Table,
  Button,
  Checkbox,
} from '@radix-ui/themes';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MachineTree } from 'types/machine';
import { AppRoutes } from 'enums/Routes.enum';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  fetchSensorsList,
  SearchIdPaginationFilters,
} from 'services/API/sensor';
import { sensorQueryKeys } from 'enums/SensorQueryKeys.enum';
import { Pagination } from 'components/Pagination';
import { SensorReference } from 'components/SensorReference';

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
export default useDebounce;

const renderSelectComponents = ({ id, label, children }: MachineTree) => (
  <>
    <Select.Label key={id}>{label}</Select.Label>
    <Select.Group>
      {children.map((child) => {
        const component =
          child.children.length === 0 ? (
            <Select.Item value={child.id} key={child.id}>
              {child.label}
            </Select.Item>
          ) : (
            <Select.Label key={child.id}>{child.label}</Select.Label>
          );
        renderSelectComponents(child);
        return component;
      })}
      <Select.Separator />
    </Select.Group>
  </>
);

const renderSelectMachines = ({ children, id, label }: MachineTree) => (
  <Select.Group key={id}>
    <Select.Label>{label}</Select.Label>
    {children.map(renderSelectComponents)}
  </Select.Group>
);

export const SensorsOverviewPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [id, setId] = useState('all');
  const [selectedSensors, setSelectedSensor] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 400);

  const itemsPerPage = 20;
  const comparableSensors = 4;
  const options: SearchIdPaginationFilters = {
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    search: debouncedSearch,
    id: id === 'all' ? '' : id,
  };

  const {
    data: { data: sensors, count } = {},
    isPending: isPendingSensors,
    isFetching,
  } = useQuery({
    queryKey: sensorQueryKeys.filteredSensors(options),
    queryFn: () => fetchSensorsList(options),
    placeholderData: keepPreviousData,
  });

  const { data: allParts, isPending } = useMachineTree();

  const handleSelect = (id: string) => {
    if (selectedSensors.includes(id)) {
      setSelectedSensor(selectedSensors.filter((s) => s !== id));
    } else if (selectedSensors.length < comparableSensors) {
      setSelectedSensor([...selectedSensors, id]);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const handleFilter = (id: string) => {
    setId(id);
    setPage(1);
  };

  if (isPending || isPendingSensors) {
    return <Spinner />;
  }

  const pages = Math.round((count || 0) / options.limit);

  return (
    <div style={{ paddingBottom: '12px' }}>
      <Flex justify="between" align="center" mb="3">
        <Heading>{t('sensors', { count: 2 })}</Heading>
        {isFetching && <Spinner />}
        <Flex gap="3">
          <Select.Root onValueChange={handleFilter} value={id}>
            <Select.Trigger />
            <Select.Content position="popper" id="eventPart">
              <Select.Item key="all" value="all">
                {t('allModels')}
              </Select.Item>
              {allParts?.map(renderSelectMachines)}
            </Select.Content>
          </Select.Root>
          <TextField.Root
            placeholder={t('search')}
            onChange={handleSearch}
            value={search}
          >
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
        </Flex>
      </Flex>

      <Table.Root variant="surface">
        <Table.Row>
          <Table.ColumnHeaderCell> </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            {t('sensors', { count: 1 })}
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>{t('unit')}</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>{t('reference')}</Table.ColumnHeaderCell>
        </Table.Row>
        {sensors?.map((sensor) => (
          <Table.Row
            key={`${sensor.timeseries_id}`}
            onClick={() => handleSelect(sensor.timeseries_id)}
          >
            <Table.Cell width="50px">
              <Checkbox
                disabled={
                  !selectedSensors.find((a) => sensor.timeseries_id === a) &&
                  selectedSensors.length >= comparableSensors
                }
                checked={
                  !!selectedSensors.find((a) => sensor.timeseries_id === a)
                }
              />
            </Table.Cell>
            <Table.RowHeaderCell>
              <Link
                weight="medium"
                color="gray"
                href={`/sensor/?sensorIds=${sensor.timeseries_id}`}
                onClick={(e) => {
                  e.preventDefault();
                  navigate({
                    pathname: AppRoutes.Sensor,
                    search: createSearchParams({
                      sensorIds: sensor.timeseries_id,
                    }).toString(),
                  });
                }}
              >
                {sensor.label}
              </Link>
            </Table.RowHeaderCell>
            <Table.Cell>{sensor.unit}</Table.Cell>
            <Table.Cell>
              {sensor.references.map((ref) => (
                <SensorReference
                  key={ref.model_id}
                  title={ref.title}
                  modelId={ref.model_id}
                />
              ))}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Root>
      <Flex p="3">
        <Flex align="center" gap="2" flexGrow="1">
          <Button
            disabled={!selectedSensors.length}
            onClick={() =>
              navigate({
                pathname: AppRoutes.Sensor,
                search: createSearchParams({
                  sensorIds: selectedSensors,
                }).toString(),
              })
            }
          >
            {t('compare')}
          </Button>
          {!!selectedSensors.length && (
            <Text>
              {t('itemsOfSelected', {
                selected: selectedSensors.length,
                total: comparableSensors,
              })}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex justify="center">
        <Pagination page={page} count={pages} onChange={setPage} />
      </Flex>
    </div>
  );
};
