import { CustomerTypeData } from 'types/state';

import { api } from './client';

export const fetchCustomerTypes = async (): Promise<CustomerTypeData[]> => {
  const {
    data: { data },
  } = await api.get('/states/types');

  return data;
};
