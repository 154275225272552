import React, { FC } from 'react';
import { isSameDay, format } from 'date-fns';

// From the Recharts documentation: tick?:((props: any) => ReactElement<SVGElement>)
// eslint-disable-next-line
export const CustomXAxisTick: FC<any> = (props) => {
  const { x, y, payload, fill, isLongDateFormat } = props;

  if (!payload) return null;

  // is today
  let data = format(
    new Date(payload.value),
    isLongDateFormat ? 'EEE do MMM' : 'HH:mm iii'
  ).split(' ');

  if (isSameDay(payload.value, new Date())) {
    data = format(new Date(payload.value), 'HH:mm').split(' ');
  }

  return (
    <text x={x} y={y} dy="0" textAnchor="middle" fill={fill}>
      <tspan x={x} dy="1.2em">
        {data[0]}
      </tspan>
      <tspan x={x} dy="1.2em">
        {data[1]} {data[2]}
      </tspan>
    </text>
  );
};
