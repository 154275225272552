import React, { FC } from 'react';
import { Box } from '@mui/system';

export const TruncateBox: FC<{ children: string }> = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      padding: '22px',
      backgroundColor: 'white',
      zIndex: 2,
      top: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '100%',
      maxWidth: '130px',
      '&:hover': {
        maxWidth: '400px',
      },
    }}
  >
    {children}
  </Box>
);
