import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { UsersTable } from 'views/Administration/UsersTable';

import { Dialog } from '@radix-ui/themes';
import { UserForm } from 'views/Administration/UserForm';

export const AdministrationPage: FC = () => {
  const { t } = useTranslation();
  const [isCreateUserFormOpen, setCreateUserFormOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={() => setCreateUserFormOpen(true)}
          variant="outlined"
          color="secondary"
          sx={{
            margin: '0 0 10px auto',
            color: (theme) => theme.palette.custom.primaryFontColor,
          }}
        >
          {t('administration.createNewUser')}
        </Button>
        <UsersTable />
      </Box>

      <Dialog.Root
        open={isCreateUserFormOpen}
        onOpenChange={setCreateUserFormOpen}
      >
        <Dialog.Content
          style={{
            maxWidth: '700px',
          }}
        >
          <Dialog.Title>{t('administration.createNewUser')}</Dialog.Title>
          <UserForm closeDialog={() => setCreateUserFormOpen(false)} />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
