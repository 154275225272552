import { useState } from 'react';

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortingType {
  LABEL = 'label',
  HEALTH = 'health',
}

type TableSorting = {
  sortingOrder: SortingOrder;
  sortingType: SortingType;
  onSetSortingType: (value: SortingType) => void;
};

export const useTableSorting = (): TableSorting => {
  const [sortingOrder, setSortingOrder] = useState<SortingOrder>(
    SortingOrder.ASC
  );
  const [sortingType, setSortingType] = useState<SortingType>(
    SortingType.LABEL
  );

  const onSetSortingType = (value: SortingType) => {
    if (value === sortingType) {
      setSortingOrder(
        sortingOrder === SortingOrder.ASC ? SortingOrder.DESC : SortingOrder.ASC
      );
    } else {
      setSortingType(value);
    }
  };

  return {
    sortingOrder,
    sortingType,
    onSetSortingType,
  };
};
