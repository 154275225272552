import {
  MIDDLE_POINT_IDENTIFIER,
  MAX_Y_AXIS,
  MIN_Y_AXIS,
} from 'utils/constants';

import { GeneralChartData } from 'types/machine';
import { CustomerEvent } from 'types/event';

const getMiddlePoint = (
  start: number,
  end: number,
  key: string,
  maxValue?: number
): GeneralChartData => {
  return {
    date: (end - start) / 2 + start,
    // We need to make a value a bit bigger than maxValue for showing only one CustomLabel for one event in any chart.
    [key]: (maxValue || MAX_Y_AXIS) + MIDDLE_POINT_IDENTIFIER,
  };
};

export const getChartEventsData = (
  firstPointTime: number,
  lastPointTime: number,
  eventsData?: CustomerEvent[],
  maxValue?: number,
  minValue?: number
): GeneralChartData[] => {
  if (!eventsData) return [];

  return eventsData.reduce((acc, event) => {
    const startInMs = new Date(event.start).getTime();
    const endInMs = new Date(event.end).getTime();

    const minusEvent = event.id.includes('minus');

    // Case when the end and the start of the event doesn't fit in a selected date range.
    if (firstPointTime > startInMs && lastPointTime < endInMs) {
      return minusEvent
        ? [
            ...acc,
            { date: firstPointTime, [event.id]: 0 },
            { date: firstPointTime + 1, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: lastPointTime - 1, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: lastPointTime, [event.id]: 0 },
          ]
        : [
            ...acc,
            // 'Min' point of the event at the start.
            { date: firstPointTime, [event.id]: minValue ?? MIN_Y_AXIS },
            // 'Max' point of the event just after the start (+1ms).
            { date: firstPointTime + 1, [event.id]: maxValue ?? MAX_Y_AXIS },
            // 'Middle' point for showing the CustomLabel.
            getMiddlePoint(firstPointTime, lastPointTime, event.id, maxValue),
            // 'Max' point of the event just before the end (-1ms).
            { date: lastPointTime - 1, [event.id]: maxValue ?? MAX_Y_AXIS },
            // 'Min' point of the event at the end.
            { date: lastPointTime, [event.id]: minValue ?? MIN_Y_AXIS },
          ];
    }

    // Case when the end of the event doesn't fit in a selected date range.
    if (lastPointTime > startInMs && lastPointTime < endInMs) {
      return minusEvent
        ? [
            ...acc,
            { date: startInMs, [event.id]: 0 },
            { date: startInMs + 1, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: lastPointTime - 1, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: lastPointTime, [event.id]: 0 },
          ]
        : [
            ...acc,
            { date: startInMs, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: startInMs + 1, [event.id]: maxValue ?? MAX_Y_AXIS },
            getMiddlePoint(startInMs, lastPointTime, event.id, maxValue),
            { date: lastPointTime - 1, [event.id]: maxValue ?? MAX_Y_AXIS },
            { date: lastPointTime, [event.id]: minValue ?? MIN_Y_AXIS },
          ];
    }

    // Case when the start of the event doesn't fit in a selected date range.
    if (firstPointTime > startInMs && firstPointTime < endInMs) {
      return minusEvent
        ? [
            ...acc,
            { date: firstPointTime, [event.id]: 0 },
            { date: firstPointTime + 1, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: endInMs - 1, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: endInMs, [event.id]: 0 },
          ]
        : [
            ...acc,
            { date: firstPointTime, [event.id]: minValue ?? MIN_Y_AXIS },
            { date: firstPointTime + 1, [event.id]: maxValue ?? MAX_Y_AXIS },
            getMiddlePoint(firstPointTime, endInMs, event.id, maxValue),
            { date: endInMs - 1, [event.id]: maxValue ?? MAX_Y_AXIS },
            { date: endInMs, [event.id]: minValue ?? MIN_Y_AXIS },
          ];
    }

    // Default case when the start and the end of the event fit in selected date range
    return minusEvent
      ? [
          ...acc,
          { date: startInMs, [event.id]: minValue ?? MAX_Y_AXIS },
          { date: startInMs + 1, [event.id]: minValue ?? MIN_Y_AXIS },
          { date: endInMs - 1, [event.id]: minValue ?? MIN_Y_AXIS },
          { date: endInMs, [event.id]: minValue ?? MAX_Y_AXIS },
        ]
      : [
          ...acc,
          { date: startInMs, [event.id]: minValue ?? MIN_Y_AXIS },
          { date: startInMs + 1, [event.id]: maxValue ?? MAX_Y_AXIS },
          getMiddlePoint(startInMs, endInMs, event.id, maxValue),
          { date: endInMs - 1, [event.id]: maxValue ?? MAX_Y_AXIS },
          { date: endInMs, [event.id]: minValue ?? MIN_Y_AXIS },
        ];
  }, [] as GeneralChartData[]);
};
