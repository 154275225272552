import React from 'react';
import { Badge } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';

import './LiveIndicator.css';

export const LiveIndicator = ({ isEnabled }: { isEnabled: boolean }) => {
  const { t } = useTranslation();

  if (!isEnabled) return null;
  return (
    <Badge color="gray">
      <div className="live-indicator">
        <span className="pulse" />
        {t('liveData')}
      </div>
    </Badge>
  );
};
