export enum Languages {
  EN = 'en',
  DE = 'de',
}

export const LanguagesLabels = Object.freeze<Record<Languages, string>>({
  [Languages.EN]: 'English',
  [Languages.DE]: 'Deutsch',
});

export const languagesLabelsOptions = Object.values(Languages).map((value) => ({
  value,
  label: LanguagesLabels[value],
}));
