import React, { FC, ReactNode, useCallback, useState } from 'react';
import { DateRangeType } from 'types/machine';
import { DateRanges } from 'enums/DateRanges.enum';
import { ChartContextProvider } from 'hooks/ChartData/useChartCacheData';

interface Props {
  children: ReactNode;
}

export const ChartDataProvider: FC<Props> = ({ children }) => {
  const [customStartDate, setCustomStartDate] = useState<Date | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Date | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState<DateRangeType>(DateRanges.DAY);

  const setCustomStartDateRange = useCallback((startDate: Date | null) => {
    setCustomStartDate(startDate);
  }, []);
  const setCustomEndDateRange = useCallback((endDate: Date | null) => {
    setCustomEndDate(endDate);
  }, []);
  const setNewDateRange = useCallback(
    (data: DateRangeType) => setDateRange(data),
    []
  );
  const setNewDate = useCallback((date: Date) => setSelectedDate(date), []);

  return (
    <ChartContextProvider
      value={{
        selectedDate,
        setNewDate,
        dateRange,
        setNewDateRange,
        customStartDate,
        customEndDate,
        setCustomStartDateRange,
        setCustomEndDateRange,
      }}
    >
      {children}
    </ChartContextProvider>
  );
};
