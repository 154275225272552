import React from 'react';
import {
  Box,
  Button,
  DataList,
  Flex,
  HoverCard,
  Link,
  Text,
} from '@radix-ui/themes';
import { MachineTree } from 'types/machine';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { useMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'enums/Routes.enum';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { ReactComponent as MachineOutlined } from 'assets/icons/machine-outlined.svg';
import { ReactComponent as ModelIcon } from 'assets/icons/sensor-outlined.svg';

const getAllLeafNodesWithPath = (
  items: MachineTree[]
): {
  path: MachineTree[];
  item: MachineTree;
}[] => {
  const paths: {
    path: MachineTree[];
    item: MachineTree;
  }[] = [];
  items.forEach((item) => {
    if (item.children.length === 0) {
      paths.push({ path: [item], item });
    } else {
      paths.push(
        ...getAllLeafNodesWithPath(item.children).map((child) => ({
          path: [item, ...child.path],
          item: child.item,
        }))
      );
    }
  });
  return paths;
};

export const SensorReference = ({
  modelId,
  title,
}: {
  modelId: string;
  title: string;
}) => {
  const { data: allParts } = useMachineTree();
  const models = getAllLeafNodesWithPath(allParts || []);
  const { t } = useTranslation();
  const machine = models.find((m) => m.item.id === modelId);

  return (
    <Box mb="2">
      <HoverCard.Root>
        <HoverCard.Trigger>
          <Button variant="ghost" color="gray" mb="1">
            <Flex align="center" gap="1">
              <MachineOutlined className="icon" />
              <Flex maxWidth="180px">
                <Text truncate>{machine?.path[0].label}</Text>
              </Flex>
            </Flex>
            <ArrowRightIcon />
            <Text>...</Text>
            <ArrowRightIcon />
            <Flex align="center" gap="1">
              <ModelIcon height="16px" />
              <Flex maxWidth="300px">
                <Text truncate>{title}</Text>
              </Flex>
            </Flex>
          </Button>
        </HoverCard.Trigger>
        <HoverCard.Content maxWidth="500px" size="1">
          <DataList.Root size="1">
            {models
              .find((m) => m.item.id === modelId)
              ?.path.map((parent: MachineTree, n: number) => {
                return (
                  <DataList.Item key={parent.id}>
                    <DataList.Label>
                      {n === 0
                        ? t('machines', { count: 1 })
                        : `${t('components', { count: 1 })} ${n}`}
                    </DataList.Label>
                    <DataList.Value>
                      <RouterLink
                        to={generatePath(AppRoutes.Detail, {
                          id: parent.id,
                        })}
                      >
                        <Link href={`/detail/${parent.id}`}>
                          {parent.label}
                        </Link>
                      </RouterLink>
                    </DataList.Value>
                  </DataList.Item>
                );
              })}
            <DataList.Item>
              <DataList.Label>{t('models', { count: 1 })}</DataList.Label>
              <DataList.Value>
                <RouterLink
                  to={generatePath(AppRoutes.Model, {
                    id: modelId,
                  })}
                >
                  <Link href={`/model/${modelId}`}>{title}</Link>
                </RouterLink>
              </DataList.Value>
            </DataList.Item>
          </DataList.Root>
        </HoverCard.Content>
      </HoverCard.Root>
    </Box>
  );
};
