import { MachineHealthData } from 'types/machine';
import { CustomerEvent } from 'types/event';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/theme';
import { useMemo } from 'react';

interface Data {
  events: CustomerEvent[];
  startDate: string | null;
}
export const useNoDataEvents = (
  healthStatusData: MachineHealthData[]
): CustomerEvent[] => {
  const { t } = useTranslation();

  const defaultNoDateEvent = {
    user_id: '',
    description: '',
    label: t('emptyMessage.default'),
    part: { id: '', identifier: '', label: '' },
    type: {
      id: '',
      icon_path: '',
      label: '',
      hexcolor: theme.iconColor,
    },
  };

  const { events } = useMemo(
    () => {
      return healthStatusData.reduce<Data>(
        (acc, item, index, array) => {
          if (item.health?.toString() === 'NaN' && !acc.startDate) {
            return { ...acc, startDate: item.date };
          }

          const isLastIndex = array.length - 1 === index;
          if (isLastIndex && acc.startDate) {
            return {
              ...acc,
              events: [
                ...acc.events,
                {
                  ...defaultNoDateEvent,
                  id: `${index}`,
                  start: acc.startDate,
                  end: item.date,
                },
              ],
              startDate: null,
            };
          }

          if (item.health?.toString() !== 'NaN' && acc.startDate) {
            return {
              ...acc,
              events: [
                ...acc.events,
                {
                  ...defaultNoDateEvent,
                  id: `${index}`,
                  start: acc.startDate,
                  end: item.date,
                },
              ],
              startDate: null,
            };
          }

          return acc;
        },
        { events: [], startDate: null }
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [healthStatusData]
  );

  return events;
};
