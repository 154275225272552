import { eachDayOfInterval, isAfter } from 'date-fns';

const DAYS_IN_TWO_WEEKS = 14;
export const getIsIntervalMoreTwoWeeks = (
  startDate?: Date | null,
  endDate?: Date | null
): boolean => {
  if (
    isAfter(
      startDate ? new Date(startDate) : new Date(),
      endDate ? new Date(endDate) : new Date()
    )
  ) {
    return false;
  }

  return (
    eachDayOfInterval({
      start: startDate ? new Date(startDate) : new Date(),
      end: endDate ? new Date(endDate) : new Date(),
    }).length > DAYS_IN_TWO_WEEKS
  );
};
