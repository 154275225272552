import React, { FC } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

interface Props {
  isPasswordVisible: boolean;
  onShowPassword: () => void;
}

export const PasswordAdornment: FC<Props> = ({
  isPasswordVisible,
  onShowPassword,
}) => (
  <InputAdornment position="end">
    <IconButton
      edge="end"
      onClick={onShowPassword}
      aria-label="Toggle password visibility"
      onMouseDown={(e) => e.preventDefault()}
    >
      {isPasswordVisible ? (
        <VisibilityOff data-testid="visibility-off" />
      ) : (
        <Visibility data-testid="visibility-on" />
      )}
    </IconButton>
  </InputAdornment>
);
