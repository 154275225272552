import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { getAPIErrorText } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { APIError } from 'types/apiError';

export const useMutationWithError = <T, A>(
  options: UseMutationOptions<T, APIError, A>
) => {
  const { t } = useTranslation();
  const { isError, error, ...rest } = useMutation<T, APIError, A>({
    ...options,
    onError(error) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    },
  });

  return { isError, error, ...rest };
};
