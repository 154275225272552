import React, { FC, MouseEvent, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { YAxisSettings } from 'enums/YAxisSettings.enum';
import { If } from 'components/If';
import { CustomYAxisRange } from 'types/sensor';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Flex, IconButton, Tooltip } from '@radix-ui/themes';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';

interface Props {
  value: CustomYAxisRange | null;
  setValue: (value: CustomYAxisRange | null) => void;
  initialValue?: CustomYAxisRange;
}

export const SensorChartSettings: FC<Props> = ({
  value,
  setValue,
  initialValue,
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<YAxisSettings>(
    value ? YAxisSettings.Custom : YAxisSettings.Auto
  );
  const [minDomain, setMinDomain] = useState<number | null>(
    initialValue?.min || null
  );
  const [maxDomain, setMaxDomain] = useState<number | null>(
    initialValue?.max || null
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const resetToAuto = () => {
    setValue(null);
    setMinDomain(null);
    setMaxDomain(null);
  };

  const selectCustomRange = () => {
    setMinDomain(initialValue?.min || 0);
    setMaxDomain(initialValue?.max || 0);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedOption === YAxisSettings.Custom) {
      setValue({ min: minDomain || 0, max: maxDomain || 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minDomain, maxDomain, selectedOption]);

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'settings-popover' : undefined;

  return (
    <>
      <Button
        aria-label="back"
        sx={{
          marginRight: 1,
          marginLeft: 'auto',
          p: '0 12px',
        }}
        variant="outlined"
        color="secondary"
        aria-describedby={id}
        onClick={isOpen ? handleClose : handleClick}
        startIcon={<SettingsIcon />}
        endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {t('charts.settings')}
      </Button>

      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box
          sx={{
            flexDirection: 'column',
            p: 2,
            width: '210px',
          }}
        >
          <Flex gap="6px">
            <Typography>{t('charts.yScale')}</Typography>
            <Tooltip content={t('tooltip.yScale')}>
              <IconButton variant="ghost" size="1" color="gray" radius="full">
                <QuestionMarkCircledIcon width="20px" height="20px" />
              </IconButton>
            </Tooltip>
          </Flex>
          <RadioGroup
            aria-labelledby="settings-group-label"
            value={selectedOption}
            sx={{ pl: '8px' }}
            onChange={(_event, value) => {
              setSelectedOption(value as YAxisSettings);
              if (value === YAxisSettings.Auto) resetToAuto();
              if (value === YAxisSettings.Custom) selectCustomRange();
            }}
            name="settings-buttons-group"
          >
            <FormControlLabel
              value={YAxisSettings.Auto}
              control={<Radio size="small" />}
              label={t('charts.auto')}
            />
            <FormControlLabel
              value={YAxisSettings.Custom}
              control={<Radio size="small" />}
              label={t('charts.custom')}
            />
          </RadioGroup>
          <If condition={selectedOption === YAxisSettings.Custom}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: '12px 0',
                alignItems: 'flex-end',
                gap: '8px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {t('charts.min')}:
                <TextField
                  value={minDomain || 0}
                  onChange={({ target }) => setMinDomain(+target.value)}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      p: '4px 8px',
                      width: '100px',
                    },
                  }}
                  type="number"
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {t('charts.max')}:
                <TextField
                  value={maxDomain || 0}
                  onChange={({ target }) => setMaxDomain(+target.value)}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      padding: '4px 8px',
                      width: '100px',
                    },
                  }}
                  type="number"
                />
              </Box>
            </Box>
          </If>
        </Box>
      </Popover>
    </>
  );
};
