import React, { FC, ReactNode, useMemo } from 'react';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { permissionsContext } from './usePermissions';

const SuperAdminPermission: UserScopes[] = [
  UserScopes.TenantSwitcher,
  UserScopes.SelectUserTenant,
  UserScopes.SetUserBetaRole,
];
const AdminPermission: UserScopes[] = [UserScopes.Administration];
const UserPermission: UserScopes[] = [UserScopes.Dashboard];
const BetaUserPermission: UserScopes[] = [
  UserScopes.Dashboard,
  UserScopes.UpdateLabels,
];
const ContributorPermission: UserScopes[] = [
  UserScopes.Dashboard,
  UserScopes.UpdateLabels,
  UserScopes.UpdateSensitivity,
];

interface Props {
  children: ReactNode;
}

export const PermissionsProvider: FC<Props> = ({ children }) => {
  const permissions = useMemo(
    () => ({
      [UserRoles.SuperAdmin]: new Set(SuperAdminPermission),
      [UserRoles.Admin]: new Set(AdminPermission),
      [UserRoles.User]: new Set(UserPermission),
      [UserRoles.BetaUser]: new Set(BetaUserPermission),
      [UserRoles.Contributor]: new Set(ContributorPermission),
    }),
    []
  );

  return (
    <permissionsContext.Provider value={permissions}>
      {children}
    </permissionsContext.Provider>
  );
};
