import React, { FC } from 'react';

import Box from '@mui/material/Box';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { PermissionsGate } from 'components/PermissionsGate';
import { TenantSwitcher } from 'components/TenantSwitcher';
import { EventList } from 'views/Event/EventList';

import { UserScopes } from 'enums/UserScopes.enum';
import * as Toolbar from '@radix-ui/react-toolbar';
import {
  Button,
  Dialog,
  Flex,
  IconButton,
  Popover,
  Separator,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import {
  CalendarIcon,
  Pencil2Icon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import { EventForm } from 'views/Event/EventForm';
import { useTranslation } from 'react-i18next';

const contentBoxStyles = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  img: {
    height: '40px',
    marginRight: '20px',
  },
};

export const Header: FC = () => {
  const { t } = useTranslation();
  const [isDrawerActive, setIsDrawerActive] = React.useState(false);
  const [isEventFormOpen, setIsEventFormOpen] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          height: '64px',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '12px 24px',
          zIndex: 2,
        }}
      >
        <Box sx={contentBoxStyles}>
          <PermissionsGate scopes={[UserScopes.Dashboard]}>
            <Breadcrumbs />
          </PermissionsGate>
        </Box>

        <Box>
          <PermissionsGate scopes={[UserScopes.TenantSwitcher]}>
            <TenantSwitcher />
          </PermissionsGate>
        </Box>

        <PermissionsGate scopes={[UserScopes.Dashboard]}>
          <Box>
            <Toolbar.Root>
              <Flex align="center" gap="2" px="4" py="3">
                <Popover.Root
                  onOpenChange={setIsDrawerActive}
                  open={isDrawerActive}
                >
                  <Toolbar.Button asChild>
                    <Popover.Trigger>
                      <Button size="3" variant="ghost" color="gray" mr="1">
                        <CalendarIcon width="20px" height="20px" />
                        Events
                      </Button>
                    </Popover.Trigger>
                  </Toolbar.Button>
                  <Popover.Content maxHeight="calc(100vh - 68px)" width="270px">
                    <EventList selectedDate={new Date('2022-01-01')} />
                  </Popover.Content>
                </Popover.Root>
                <Toolbar.Separator>
                  <Separator orientation="vertical" mx="2" />
                </Toolbar.Separator>
                <Toolbar.Button asChild>
                  <IconButton
                    size="3"
                    variant="ghost"
                    color="gray"
                    onClick={() => setIsEventFormOpen(true)}
                  >
                    <Pencil2Icon width="20px" height="20px" />
                  </IconButton>
                </Toolbar.Button>
              </Flex>
            </Toolbar.Root>
          </Box>
        </PermissionsGate>
      </Box>
      <PermissionsGate scopes={[UserScopes.Dashboard]}>
        <Dialog.Root onOpenChange={setIsEventFormOpen} open={isEventFormOpen}>
          <Dialog.Content
            style={{
              maxWidth: '700px',
            }}
          >
            <Dialog.Title>
              <Flex justify="between">
                <Text>{t('events.createEvent')}</Text>
                <Tooltip content={t('tooltip.createEvent')}>
                  <IconButton
                    variant="ghost"
                    size="1"
                    color="gray"
                    radius="full"
                  >
                    <QuestionMarkCircledIcon width="20px" height="20px" />
                  </IconButton>
                </Tooltip>
              </Flex>
            </Dialog.Title>
            <EventForm closeDialog={() => setIsEventFormOpen(false)} />
          </Dialog.Content>
        </Dialog.Root>
      </PermissionsGate>
    </>
  );
};
