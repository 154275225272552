export type UserQueryFilters = {
  offset?: number;
  limit?: number;
  'X-Tenant-Id'?: string;
};

export const userQueryKeys = {
  userData: ['userData'],
  users: ['users'],
  filteredUsers: (
    filters: UserQueryFilters
  ): (string | { filters: UserQueryFilters })[] => [
    ...userQueryKeys.users,
    { filters },
  ],
};
