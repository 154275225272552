import {
  CreateEventFormData,
  CustomerEvent,
  EditEventFormData,
} from 'types/event';

import { api } from './client';

export interface CustomerEventsWithPagination {
  data: CustomerEvent[];
  nextStart: string;
}

export const fetchEvents = async (
  start: string
): Promise<CustomerEventsWithPagination> => {
  const {
    data: { data },
  } = await api.get(`/events?start=${start}&limit=10`);

  const lastEvent = data[data.length - 1];

  return {
    data,
    nextStart: lastEvent?.start,
  };
};

export const createEvent = async (
  event: CreateEventFormData
): Promise<CustomerEvent> => {
  const {
    data: { data },
  } = await api.post('/events', event);

  return data;
};

export const deleteEvent = async (id: string): Promise<null> => {
  return await api.delete(`/events/${id}`);
};

export const editEvent = async (
  eventData: EditEventFormData
): Promise<CustomerEvent> => {
  const {
    data: { data },
  } = await api.put(`/events/${eventData.id}`, eventData);

  return data;
};
