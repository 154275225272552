import React, { FC } from 'react';

import Box from '@mui/material/Box';

import logoSecondary from 'assets/images/logo-secondary.svg';

export const Footer: FC = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 40px 12px',
      marginTop: '-24px',
    }}
  >
    <img src={logoSecondary} alt="logo-icon" style={{ height: '50px' }} />
  </Box>
);
