export enum UserRoles {
  SuperAdmin = 'ROLE_SUPER',
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
  BetaUser = 'ROLE_BETA',
  Contributor = 'ROLE_CONTRIBUTER',
}
export const UserRolesLabels = Object.freeze<Record<UserRoles, string>>({
  [UserRoles.SuperAdmin]: 'roles.super',
  [UserRoles.Admin]: 'roles.admin',
  [UserRoles.User]: 'roles.user',
  [UserRoles.BetaUser]: 'roles.beta',
  [UserRoles.Contributor]: 'roles.contributor',
});
