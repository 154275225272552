import React, { useContext } from 'react';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuth } from 'hooks/Auth/useAuth';

type Permissions = {
  [x in UserRoles]: Set<UserScopes>;
};

export const permissionsContext = React.createContext<Permissions>({
  [UserRoles.Admin]: new Set(),
  [UserRoles.SuperAdmin]: new Set(),
  [UserRoles.User]: new Set(),
  [UserRoles.BetaUser]: new Set(),
  [UserRoles.Contributor]: new Set(),
});

export const hasPermission = (
  permissions: Set<UserScopes>,
  scopes: UserScopes[]
): boolean => scopes.some((scope) => permissions.has(scope));

export const usePermissions = (): Permissions => useContext(permissionsContext);

export const useHasPermission = (scopes: UserScopes[]): boolean => {
  const { userData } = useAuth();
  const permissions = usePermissions();

  const allRolesPermissions: Set<UserScopes> =
    userData?.roles.reduce((acc, role) => {
      const permission = permissions[role];

      return permission ? new Set([...acc, ...permission]) : acc;
    }, new Set<UserScopes>()) || new Set<UserScopes>();

  return !!userData && hasPermission(allRolesPermissions, scopes);
};
