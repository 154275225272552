import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ScrollToTop } from 'components/ScrollToTop';

import { AuthProvider } from 'services/Auth';
import { PermissionsProvider } from 'services/Permissions';
import { ChartDataProvider } from 'services/ChartData';
import { MuiThemeProvider } from 'styles/MuiThemeProvider';
import { Theme } from '@radix-ui/themes';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'localization/i18n';
import 'react-toastify/dist/ReactToastify.css';
import '@radix-ui/themes/styles.css';

import 'styles/accentColor.css';
import './index.css';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>
        <AuthProvider>
          <ChartDataProvider>
            <MuiThemeProvider>
              <ToastContainer draggablePercent={30} pauseOnFocusLoss={false} />
              <BrowserRouter>
                <Theme accentColor="lime" radius="large" grayColor="slate">
                  <App />
                </Theme>
                <ScrollToTop />
              </BrowserRouter>
            </MuiThemeProvider>
          </ChartDataProvider>
        </AuthProvider>
      </PermissionsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
