import { OverallStateData } from 'types/state';

import * as queryString from 'query-string';
import { api } from './client';
import { TimeRangeQuery } from './machine';

export const fetchOverallStateData = async (
  timeRangeQuery: TimeRangeQuery,
  signal?: AbortSignal
): Promise<OverallStateData[]> => {
  const query = queryString.stringify(timeRangeQuery);

  const {
    data: { data },
  } = await api.get(`/states/aggregate?${query}`, { signal });

  return data;
};

export const fetchMachineOverallStateData: (
  machineId: string,
  timeRangeQuery: TimeRangeQuery,
  signal?: AbortSignal
) => Promise<OverallStateData[]> = async (
  machineId,
  timeRangeQuery,
  signal
) => {
  const query = queryString.stringify(timeRangeQuery);

  const {
    data: { data },
  } = await api.get(`/states/aggregate/${machineId}?${query}`, {
    signal,
  });

  return data;
};
