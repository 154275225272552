import React, { FC } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { theme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Languages } from 'enums/Languages.enum';

interface Props {
  date?: string;
  payloads?: Payload<string, string>[];
  valueFormatter?: (value: string) => string;
  isRangeMoreOneDay?: boolean;
}

export const ChartTooltip: FC<Props> = ({
  date,
  payloads,
  valueFormatter,
  isRangeMoreOneDay,
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;

  const currentDate = date ? new Date(date) : new Date();
  const formattedDate = isRangeMoreOneDay
    ? format(currentDate, 'd LLL yyyy\u00A0\u00A0\u00A0HH:mm', { locale }) // \u00A0 is a non-breaking space
    : format(currentDate, 'HH:mm:ss', { locale });

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '4px',
        boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.2)',
      }}
    >
      {date && (
        <Typography
          sx={{
            p: '4px 8px',
            mb: '8px',
            borderBottom: `1px solid ${theme.borderColor}`,
          }}
          color="secondary"
        >
          {formattedDate}
        </Typography>
      )}
      {payloads?.map(({ color, dataKey, name, value }) => (
        <Box
          key={dataKey}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            p: '0 8px',
          }}
        >
          <Box
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '4px',
              backgroundColor: color,
              mr: '4px',
            }}
          />
          <Typography color="secondary">{name?.toUpperCase()}:</Typography>
          <Typography color="secondary">
            {valueFormatter ? valueFormatter(value || '') : value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
