import { UserQueryFilters, userQueryKeys } from 'enums/UserQueryKeys.enum';
import { fetchUsers, UsersFullData } from 'services/API/user';
import { useQueryWithError } from 'hooks/useQueryWithError';

export const useUsers = (props: UserQueryFilters) => {
  return useQueryWithError<UsersFullData>({
    queryKey: userQueryKeys.filteredUsers(props),
    queryFn: () => fetchUsers(props),
    retry: 0,
  });
};
