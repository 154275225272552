import { TimeRangeQuery } from 'services/API/machine';

export const getCustomTimeRangeQuery = (
  customStartDate?: Date | null,
  customEndDate?: Date | null
): TimeRangeQuery => {
  return {
    start: customStartDate?.toISOString(),
    end: customEndDate?.toISOString(),
  };
};
