import { APIError } from 'types/apiError';

export const getAPIErrorText = ({ response }: APIError): string => {
  if (!response) {
    return '';
  }

  if (response.data?.detail) {
    return response.data?.detail;
  }

  if (response.data?.meta) {
    return Object.values(response.data?.meta).join('. ');
  }

  return '';
};
