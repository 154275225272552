export type DetailsQueryFilters = {
  id: string;
  offset?: number;
  limit?: number;
};

export const detailsQueryKeys = {
  detail: ['detail'],
  details: ['details'],
  filteredDetails: (
    filters: DetailsQueryFilters
  ): (string | { filters: DetailsQueryFilters })[] => [
    ...detailsQueryKeys.details,
    { filters },
  ],
  detailPath: (id: string): string[] => [...detailsQueryKeys.detail, id],
};
