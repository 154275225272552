import { User } from 'types/user';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export interface SignInProps {
  email: string;
  password: string;
}

export interface UserData {
  token: string;
  user: User;
}

export const signIn = async (props: SignInProps): Promise<UserData> => {
  const {
    data: { data },
  } = await axios.post('/signin', props);

  return data;
};

export const microsoftSignIn = async (): Promise<string> => {
  const { data } = await axios.get('/microsoft-start-signin');

  return data;
};

export const microsoftCompleteSignIn = async (
  query: string
): Promise<UserData> => {
  const { data } = await axios.get(`/microsoft-complete-signin${query}`);

  return data.data;
};

export const microsoftSignOff = async (): Promise<string> => {
  return await axios.get('/microsoft-signoff');
};

export const signUp = async (props: SignInProps): Promise<UserData> => {
  const {
    data: { data },
  } = await axios.post('/set-password', props);

  return data;
};
