import React, { FC } from 'react';

import Box from '@mui/material/Box';
import { MachinesTable } from 'views/Dashboard/MachinesTable';
import { OverallStates } from 'views/Dashboard/OverallStates';

import { MostCriticalTable } from 'components/MostCriticalTable';

const boxStyles = { display: 'flex', gap: '8px', marginBottom: '24px' };

export const DashboardPage: FC = () => {
  return (
    <>
      <Box sx={boxStyles}>
        <OverallStates />
        <MostCriticalTable isMachinesList />
      </Box>
      <Box sx={boxStyles}>
        <MachinesTable />
      </Box>
    </>
  );
};
