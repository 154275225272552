import { overallStateQueryKeys } from 'enums/OverallStateQueryKeys.enum';
import { fetchCustomerTypes } from 'services/API/customerTypes';
import {
  fetchMachineOverallStateData,
  fetchOverallStateData,
} from 'services/API/overallState';
import { keepPreviousData } from '@tanstack/react-query';
import { TotalOverallStateData } from 'types/state';
import {
  ONE_DAY_IN_MS,
  REFRESH_OVERALL_STATES_INTERVAL,
} from 'utils/constants';
import { useAbortQueryOnCleanup } from 'hooks/useAbortQueryOnCleanup';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { TimeRangeQuery } from '../../services/API/machine';

export const useGetOverallStateData = ({
  machineId,
  timeRange,
}: {
  machineId?: string;
  timeRange?: TimeRangeQuery;
}) => {
  const timeRangeQuery: TimeRangeQuery = timeRange || {
    start: new Date(Date.now() - ONE_DAY_IN_MS).toISOString(),
    end: new Date(Date.now()).toISOString(),
  };

  useAbortQueryOnCleanup(
    machineId
      ? overallStateQueryKeys.machineStateData({
          id: machineId,
          timeRange: timeRangeQuery,
        })
      : overallStateQueryKeys.stateData
  );

  return useQueryWithError<TotalOverallStateData[]>({
    queryKey: machineId
      ? overallStateQueryKeys.machineStateData({
          id: machineId,
          timeRange: timeRangeQuery,
        })
      : overallStateQueryKeys.stateData,
    queryFn: async ({ signal }) => {
      const types = await fetchCustomerTypes();
      const values = machineId
        ? await fetchMachineOverallStateData(machineId, timeRangeQuery, signal)
        : await fetchOverallStateData(timeRangeQuery, signal);

      const result = types.map((type) => {
        const itemData = values?.find((item) => item.id === type.id);

        return { ...type, ...itemData };
      }) as TotalOverallStateData[];

      return [...result].sort((a, b) => b.value - a.value);
    },
    retry: 0,
    placeholderData: keepPreviousData,
    refetchInterval: REFRESH_OVERALL_STATES_INTERVAL,
  });
};
