import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { STALE_DATA_TIME } from 'utils/constants';
import { toast } from 'react-toastify';
import { getAPIErrorText } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { APIError } from 'types/apiError';

export const useQueryWithError = <T>(options: UseQueryOptions<T, APIError>) => {
  const { t } = useTranslation();
  const { isError, error, ...rest } = useQuery<T, APIError>({
    staleTime: STALE_DATA_TIME,
    ...options,
  });

  useEffect(() => {
    if (isError) {
      toast.error(getAPIErrorText(error) || t('errors.defaultError'));
    }
  }, [isError, t, error]);

  return { isError, error, ...rest };
};
