import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetWidgetData } from 'hooks/Widget/useGetWidgetData';
import { CriticalSensorWidgets } from 'components/CriticalSensorWidgets';

export const DetailCriticalSensorWidgets: FC = () => {
  const { id } = useParams();
  const { data: widgetData } = useGetWidgetData(id);

  return <CriticalSensorWidgets data={widgetData} />;
};
