import { TimeRangeQuery } from 'services/API/machine';

export const overallStateQueryKeys = {
  stateData: ['stateData'],
  machineStateData: ({
    id,
    timeRange,
  }: {
    id: string;
    timeRange: TimeRangeQuery;
  }): string[] => {
    const { start, end } = timeRange;

    if (start && end) {
      return [...overallStateQueryKeys.stateData, id, start, end];
    }

    return [...overallStateQueryKeys.stateData, id];
  },
};
