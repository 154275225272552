import { userQueryKeys } from 'enums/UserQueryKeys.enum';
import { useAuth } from 'hooks/Auth/useAuth';
import { LocalStorage } from 'services/LocalStorage';
import { STORE_AUTH_TOKEN } from 'utils/constants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { microsoftCompleteSignIn, UserData } from 'services/API/auth';
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AppRoutes } from 'enums/Routes.enum';

export const useMicrosoftMe = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);
  const { userData, onChangeUserData, logOut } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { mutate: completeSignIn, ...rest } = useMutation<UserData>({
    mutationKey: userQueryKeys.userData,
    mutationFn: () => microsoftCompleteSignIn(location.search),
    onSuccess({ token, user }) {
      onChangeUserData(user);
      LocalStorage.setItem(STORE_AUTH_TOKEN, token);
      setSearchParams('');
      navigate(AppRoutes.Dashboard);
    },
    onError() {
      logOut();
    },
  });

  useEffect(() => {
    if (!token && !userData && !!code) {
      completeSignIn();
    }
  }, [code, token, userData, completeSignIn]);

  return { completeSignIn, ...rest };
};
