import { FetchFilterProps } from 'services/API/machine';

export type MachinesQueryFilters = {
  offset?: number;
  limit?: number;
};

export const machinesQueryKeys = {
  machines: ['machines'],
  machine: ['machine'],
  machineEvents: ['machine-events'],
  machineHealth: ['machine-health'],
  machineSensors: ['machine-sensors'],
  allMachines: () => [...machinesQueryKeys.machines, 'all'],
  filteredMachines: (
    filters: MachinesQueryFilters
  ): (string | { filters: MachinesQueryFilters })[] => [
    ...machinesQueryKeys.machines,
    { filters },
  ],
  filteredMachineHealth: (
    filters: FetchFilterProps
  ): (string | { filters: FetchFilterProps })[] => [
    ...machinesQueryKeys.machineHealth,
    { filters },
  ],
  filteredMachineEvents: (
    filters: FetchFilterProps
  ): (string | { filters: FetchFilterProps })[] => [
    ...machinesQueryKeys.machineEvents,
    { filters },
  ],
  machineWidget: (id: string): (string | { id: string })[] => [
    ...machinesQueryKeys.machine,
    { id },
  ],
};
