import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material';
import { deDE, enUS } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

import { Languages } from 'enums/Languages.enum';

import { muiTheme } from 'styles/muiTheme';

interface Props {
  children: ReactNode;
}

export const MuiThemeProvider: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enUS : deDE;

  const themeWithLocale = React.useMemo(
    () => createTheme(muiTheme, locale),
    [locale]
  );

  return <ThemeProvider theme={themeWithLocale}>{children}</ThemeProvider>;
};
