import React, { FC } from 'react';
import { Props } from 'recharts/types/component/DefaultLegendContent';

import Box from '@mui/material/Box';

import { CustomerEvent } from 'types/event';

interface SensorCustomLegendProps extends Props {
  eventsData?: CustomerEvent[];
}

export const SensorCustomLegend: FC<SensorCustomLegendProps> = ({
  payload,
  eventsData,
}) => {
  if (!payload || !eventsData) return null;

  const filteredPayload = payload.filter(
    (item) => !eventsData.some((eventType) => eventType.id === item.value)
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '40px',
      }}
    >
      {filteredPayload.map((entry) => (
        <Box
          key={entry.value}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              height: '2px',
              width: '30px',
              backgroundColor: entry.color,
              marginRight: '10px',
            }}
          />
          <span style={{ color: entry.color }}>{entry.value}</span>
        </Box>
      ))}
    </Box>
  );
};
