import { UserQueryFilters } from 'enums/UserQueryKeys.enum';
import * as queryString from 'query-string';
import { api } from 'services/API/client';
import { CreateUserFormData, EditUserFormData, User } from 'types/user';

export const loadMe = async (): Promise<User> => {
  const {
    data: { data },
  } = await api.get('/users/me');

  return data;
};

export const createUser = async ({
  tenantId,
  ...user
}: CreateUserFormData): Promise<User> => {
  const {
    data: { data },
  } = await api.post(`/users`, user, {
    headers: {
      'X-Tenant-Id': tenantId,
    },
  });

  return data;
};

export interface DeleteUserProps {
  userId: string;
}

export const deleteUser = async (props: DeleteUserProps): Promise<null> => {
  return await api.delete(`/users/${props.userId}`);
};

export interface UsersFullData {
  data: User[];
  meta: {
    count: number;
  };
}

export const editUser = async (user: EditUserFormData): Promise<User> => {
  const {
    data: { data },
  } = await api.put(
    `/users/${user.userId}`,
    {
      username: user.username,
      roles: user.roles,
    },
    {
      headers: {
        'X-Tenant-Id': user.tenantId,
      },
    }
  );

  return data;
};

export const fetchUsers = async (
  usersQuery: UserQueryFilters
): Promise<UsersFullData> => {
  const query = queryString.stringify(usersQuery);

  const { data } = await api.get(`/users?${query}`);
  return data;
};
