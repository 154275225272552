import { MachineTree } from 'types/machine';

export const getAllModels = (machineTree: MachineTree): MachineTree[] => {
  const children = machineTree.children || [];
  if (children.length === 0) {
    return [machineTree];
  }
  return children.flatMap(getAllModels);
};

export const getAllComponents = (
  machineTree: MachineTree,
  n = 0
): MachineTree[] => {
  const children = machineTree.children || [];
  if (children.length === 0) {
    return [machineTree];
  }
  if (n === 0) {
    return children.flatMap((child) => getAllComponents(child, n - 1));
  }
  return [
    machineTree,
    ...children.flatMap((child) => getAllComponents(child, n - 1)),
  ];
};
