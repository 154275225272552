import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTenants } from 'hooks/Tenant/useTenants';
import { useAuth } from 'hooks/Auth/useAuth';

import { useHasPermission } from 'services/Permissions/usePermissions';

import { CreateUserFormData, EditUserFormData, User } from 'types/user';

import { ErrorMessage } from 'components/ErrorMessage';

import { EMPTY_STATE } from 'utils/constants';
import { UserScopes } from 'enums/UserScopes.enum';
import { UserRoles, UserRolesLabels } from 'enums/UserRoles.enum';
import {
  Button,
  Flex,
  TextField,
  Text,
  CheckboxCards,
  Select,
} from '@radix-ui/themes';
import { createUser, editUser } from 'services/API/user';
import { queryClient } from 'index';
import { userQueryKeys } from 'enums/UserQueryKeys.enum';
import { toast } from 'react-toastify';
import { z } from 'services/SchemaValidation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutationWithError } from 'hooks/useMutationWithError';

interface UserFormProps {
  closeDialog: () => void;
  user?: User;
}

export const UserForm: FC<UserFormProps> = ({ closeDialog, user }) => {
  const { t } = useTranslation();
  const { userData } = useAuth();

  const hasPermissionSelectUserTenant = useHasPermission([
    UserScopes.SelectUserTenant,
  ]);

  const UserFormSchema = z.object({
    username: z.string().min(4).max(30),
    email: z.string().email(),
    roles: z.array(z.nativeEnum(UserRoles)),
    tenantId: z.string().refine((tenantId) => {
      if (hasPermissionSelectUserTenant) {
        return tenantId.length > 0;
      }
      return true;
    }),
  });

  const { mutate: sendEditUser } = useMutationWithError<User, EditUserFormData>(
    {
      mutationFn: editUser,
      onSuccess(updatedUser) {
        toast.success(
          t('toast.userUpdated', { userName: updatedUser.username })
        );
        queryClient.invalidateQueries({ queryKey: userQueryKeys.users });
        closeDialog();
      },
    }
  );

  const { mutate: sendCreateUser } = useMutationWithError<
    User,
    CreateUserFormData
  >({
    mutationFn: createUser,
    onSuccess(newUser) {
      toast.success(t('toast.userCreated', { userName: newUser.username }));
      queryClient.invalidateQueries({ queryKey: userQueryKeys.users });
      closeDialog();
    },
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserFormData>({
    mode: 'onSubmit',
    resolver: zodResolver(UserFormSchema),
    defaultValues: {
      username: user ? user.username : '',
      email: user ? user.email : '',
      tenantId: hasPermissionSelectUserTenant ? '' : userData?.tenant.id,
      roles: user ? user.roles : [UserRoles.User],
    },
  });

  const { data: tenants } = useTenants();

  const userCanSetUserBetaRole = useHasPermission([UserScopes.SetUserBetaRole]);
  const userRolesOptions = useMemo(() => {
    const roles = userCanSetUserBetaRole
      ? [UserRoles.Admin, UserRoles.BetaUser, UserRoles.Contributor]
      : [UserRoles.Admin, UserRoles.Contributor];

    return roles.map((value) => ({
      value,
      label: UserRolesLabels[value],
    }));
  }, [userCanSetUserBetaRole]);

  return (
    <>
      <Flex direction="column" gap="1">
        <Flex justify="between">
          <label htmlFor="userUsername">
            <Text as="div" size="2" weight="bold">
              {t('forms.username.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.username} />
        </Flex>
        <TextField.Root
          autoFocus
          id="userUsername"
          placeholder={t('forms.username.placeholder')}
          size="3"
          mb="3"
          {...register('username')}
        />

        <Flex justify="between">
          <label htmlFor="userEmail">
            <Text as="div" size="2" weight="bold">
              {t('forms.email.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.email} />
        </Flex>
        <TextField.Root
          type="email"
          id="userEmail"
          placeholder={t('forms.email.placeholder')}
          size="3"
          mb="3"
          {...register('email')}
        />
        <>
          <Flex justify="between">
            <label htmlFor="eventType">
              <Text as="div" size="2" weight="bold">
                {t('forms.company.label')}
              </Text>
            </label>
            <ErrorMessage error={errors.tenantId} />
          </Flex>
          <Controller
            control={control}
            name="tenantId"
            render={({ field }) => (
              <Select.Root
                size="3"
                onValueChange={field.onChange}
                {...field}
                value={field.value as string}
              >
                <Select.Trigger
                  mb="3"
                  placeholder={t('forms.company.placeholder')}
                />
                <Select.Content position="popper" id="eventType">
                  {(tenants || []).map((tenant) => (
                    <Select.Item key={tenant.id} value={tenant.id}>
                      {tenant.label || EMPTY_STATE}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            )}
          />
        </>
        <Flex justify="between">
          <label htmlFor="userRoles">
            <Text as="div" size="2" weight="bold">
              {t('forms.roles.label')}
            </Text>
          </label>
          <ErrorMessage error={errors.roles} />
        </Flex>
        <Controller
          control={control}
          name="roles"
          render={({ field }) => (
            <CheckboxCards.Root
              columns={{ initial: '1', sm: '2' }}
              onValueChange={field.onChange}
              {...field}
              defaultValue={user?.roles}
            >
              {userRolesOptions.map(({ value, label }) => {
                return (
                  <CheckboxCards.Item value={value} key={value}>
                    <Flex direction="column" width="100%" justify="end">
                      <Text weight="bold">{t(`${label}.title`)}</Text>
                      <Text>{t(`${label}.description`)}</Text>
                    </Flex>
                  </CheckboxCards.Item>
                );
              })}
            </CheckboxCards.Root>
          )}
        />
      </Flex>
      <Flex gap="3" mt="4" justify="end">
        <Button onClick={closeDialog} variant="soft" color="gray">
          {t('forms.cancel')}
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit((formData) => {
            if (user) {
              return sendEditUser({
                ...formData,
                userId: user.id,
                tenantId: formData.tenantId,
              });
            }
            sendCreateUser({
              ...formData,
              email: formData.email.toLowerCase(),
            });
          })}
        >
          {t('forms.save')}
        </Button>
      </Flex>
    </>
  );
};
