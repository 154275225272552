import React from 'react';

import { Column } from 'components/BasicTable';
import { SortingType } from 'hooks/Table/useTableSorting';

import { Detail } from 'types/detail';
import { DetailTableChart } from 'views/ChartView/DetailTableChart';
import { TruncateBox } from 'components/TruncateBox';

export const columns: Column<Detail>[] = [
  {
    id: 'name',
    label: 'tableHeader.name',
    minWidth: 130,
    format: ({ label }) => <TruncateBox>{label}</TruncateBox>,
    cellPadding: '0',
    sx: {
      position: 'relative',
    },
    sortingType: SortingType.LABEL,
  },
  {
    id: 'graph',
    label: '',
    minWidth: 200,
    cellPadding: '0',
    format: (item, settings) => (
      <DetailTableChart itemId={item.id} isShowTicks={settings?.isLastItem} />
    ),
  },
];
