export const theme = {
  // Colors
  white: '#FFFFFF',
  blue: '#1976D2',
  lightBlue: '#29B6F6',
  darkBlue: '#134D62',
  green: '#2E7D32',
  greenText: '#a8c67f',
  greenLight: '#d8fbd0',
  greenHover: '#88D38C',
  gray: '#A6A6A6',
  violet: '#AB47BC',
  pink: '#C2185B',
  yellow: '#FFFF00',
  orange: '#ED6C02',
  red: '#D32F2F',
  transparentViolet: 'rgba(206, 147, 216, 0.16)',
  transparentPink: 'rgba(252, 228, 236, 1)',
  transparentOrange: 'rgba(237, 108, 2, 0.3)',
  transparentRed: 'rgba(211, 47, 47, 0.3)',
  transparentWhite: 'rgba(255, 255, 255, 0.6)',

  backgroundColor: '#FAFAFA',
  tooltipBackgroundColor: 'rgba(95, 95, 95, 1)',
  borderColor: 'rgba(0, 0, 0, 0.12)',
  hoverColor: 'rgba(0, 0, 0, 0.06)',
  iconColor: 'rgba(0, 0, 0, 0.54)',

  // Font colors
  primaryFontColor: 'rgba(0, 0, 0, 0.87)',
  secondaryFontColor: 'rgba(0, 0, 0, 0.6)',
  lightFontColor: 'rgba(0, 0, 0, 0.26)',

  // Fonts
  primaryFont: "'Roboto', sans-serif",
};
