import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PickersLayoutProps } from '@mui/x-date-pickers';
import { DateOrTimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';

import './CalendarLayout.styles.css';

export const CalendarLayout = (
  props: PickersLayoutProps<Date | null, Date, DateOrTimeViewWithMeridiem>
) => {
  const { t } = useTranslation();
  const { children, onAccept } = props;

  return (
    <div className="box" style={{ position: 'relative' }}>
      {children}
      <Box
        sx={{
          padding: '8px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={onAccept} variant="text">
          {t('forms.ok')}
        </Button>
      </Box>
    </div>
  );
};
