import * as queryString from 'query-string';

import {
  Sensor,
  SensorReading,
  SensorReadingResponse,
  SensorSensitivityResponse,
  SensorSensitivityUpdateFormData,
} from 'types/sensor';

import { api } from './client';
import { PaginationFilters, TimeRangeQuery } from './machine';

export interface SearchIdPaginationFilters extends PaginationFilters {
  id: string;
  search: string;
}

export interface FetchSensorProps {
  timeRangeQuery: TimeRangeQuery;
  id?: string;
}

export interface FetchModelSensorDataProps extends FetchSensorProps {
  dataKey?: string;
}

export interface SensorResponsePaginated {
  data: Sensor[];
  count: number;
}

export const fetchSensorData = async (
  props: FetchModelSensorDataProps
): Promise<SensorReadingResponse> => {
  const { timeRangeQuery, id } = props;
  const query = queryString.stringify(timeRangeQuery);

  if (!id) return {};

  const {
    data: { data },
  } = await api.get(`/machines/${id}/readings?${query}`);

  return data;
};

export const fetchSensorSensitivityData = async (
  id: string
): Promise<SensorSensitivityResponse> => {
  const {
    data: { data },
  } = await api.get(`/models/${id}/details`);

  return data;
};

export const updateSensorSensitivity = async ({
  id,
  sensitivity,
}: SensorSensitivityUpdateFormData): Promise<SensorSensitivityResponse> => {
  const {
    data: { data },
  } = await api.put(`/models/${id}`, {
    sensitivity,
  });

  return data;
};

export const fetchSensorsList = async (
  sensorsQuery: SearchIdPaginationFilters
): Promise<SensorResponsePaginated> => {
  const query = queryString.stringify(sensorsQuery);

  const { data } = await api.get(`/sensors?${query}`);

  return { data: data.data, count: data.meta.count };
};

export const fetchSensorTimeseries = async (
  props: FetchSensorProps
): Promise<SensorReading> => {
  const query = queryString.stringify(props.timeRangeQuery);
  const {
    data: { data },
  } = await api.get(`/sensors/${props.id}?${query}`);

  return data;
};
