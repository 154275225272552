import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TotalOverallStateData } from 'types/state';
import { useGetOverallStateData } from 'hooks/Chart/useGetOverallStateData';
import { theme } from 'styles/theme';
import { OverallStatesChart } from 'views/ChartView/OverallStatesChart';
import { TimeRangeQuery } from 'services/API/machine';
import { EventTypes } from 'enums/EventTypes.enum';

interface OverallStatesChartProps {
  machineId?: string;
  timeRange?: TimeRangeQuery;
  children?: ReactElement;
}

export const OverallStates: FC<OverallStatesChartProps> = ({
  machineId,
  timeRange,
  children,
}) => {
  const { t } = useTranslation();
  const [overallStateData, setOverallStateData] = useState<
    TotalOverallStateData[]
  >([]);
  const { data: stateData } = useGetOverallStateData({ machineId, timeRange });

  const emptyState = useMemo(
    () => [
      {
        hexcolor: theme.borderColor,
        icon_path: '',
        id: '',
        identifier: EventTypes.INACTIVE,
        label: t('emptyMessage.default'),
        value: 100,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (stateData) {
      const filteredStates = stateData.filter((state) => state?.value > 0);
      setOverallStateData(filteredStates.length ? filteredStates : emptyState);
    }
  }, [emptyState, stateData]);

  return (
    <OverallStatesChart
      stateData={overallStateData}
      renderDateRangeLabel={children}
    />
  );
};
